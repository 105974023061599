import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createHashHistory } from 'history';
import App from './App';
import { rootReducer } from './js/store/reducers';
import logger from './logger';

const history = createHashHistory({
    hashType: 'noslash',
});

export const store = createStore(rootReducer, applyMiddleware(thunk, routerMiddleware(history), logger()));

const urls = [
    'welcome',
    'wizard-create-public',
    'wizard-create-step-1',
    'wizard-create-step-2',
    'marathons',
    'marathon-create',
    'marathon-about',
    'marathon-create-test',
];

const pathRegex = `/:pageId(${urls.join('|')}|)?`;

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Route path={pathRegex} component={props => <App pageId={props.match.params.pageId} />} />
        </Router>
    </Provider>,
    document.getElementById('root'),
);
