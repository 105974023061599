import vkBridge from '@vkontakte/vk-bridge';
import { setAuthTokenFail, setAuthTokenSuccess } from '../store/vk/actions';
import { store } from '../../index';
import { setOneStorage, setStorage } from '../store/storage/actions';
import { COURSES_PER_PAGE } from './_globals';
import { getAppId, getNewRequestId } from './_functions';

const API_VERSION = '5.124';
const STORAGE_KEYS = ['isAddedLeft', 'lastSelectedPublic'];

// Инит приложения
export const initApp = () => {
    return new Promise(resolve => {
        const handler = async e => {
            e = e.detail;
            let found = false;

            if (e.type === 'VKWebAppInitResult') {
                found = true;
                resolve();
            }

            if (found) {
                vkBridge.unsubscribe(handler);
            }
        };
        vkBridge.subscribe(handler);
        vkBridge.send('VKWebAppInit', { no_toolbar: true });
    });
};

// Скролл
export const goToTop = (top = 1) => {
    return new Promise(resolve => {
        const handler = async e => {
            e = e.detail;
            let found = false;

            if (e.type === 'VKWebAppScrollResult') {
                found = true;
                resolve();
            }

            if (found) {
                vkBridge.unsubscribe(handler);
            }
        };
        vkBridge.subscribe(handler);
        vkBridge.send('VKWebAppScroll', { top, speed: 1000 });
    });
};

// Получение токена
export const getAuthToken = (scope = '') => async dispatch => {
    return new Promise(resolve => {
        const handler = async e => {
            e = e.detail;
            let found = false;
            switch (e.type) {
                case 'VKWebAppAccessTokenReceived':
                    found = true;
                    dispatch(setAuthTokenSuccess(e.data.access_token));
                    resolve();
                    break;

                case 'VKWebAppAccessTokenFailed':
                    found = true;
                    dispatch(setAuthTokenFail());
                    resolve();
                    break;

                default:
                    break;
            }
            if (found) {
                vkBridge.unsubscribe(handler);
            }
        };
        vkBridge.subscribe(handler);
        vkBridge.send('VKWebAppGetAuthToken', {
            app_id: getAppId(),
            scope,
        });
    });
};

// Получение переменных из хранилища
export const getStorage = () => async dispatch => {
    return new Promise(resolve => {
        const handler = async e => {
            e = e.detail;
            let found = false;
            switch (e.type) {
                case 'VKWebAppStorageGetResult':
                    found = true;
                    resolve(dispatch(setStorage(e.data)));
                    break;

                case 'VKWebAppStorageGetFailed':
                    found = true;
                    resolve(false);
                    break;

                default:
                    break;
            }
            if (found) {
                vkBridge.unsubscribe(handler);
            }
        };
        vkBridge.subscribe(handler);
        vkBridge.send('VKWebAppStorageGet', { keys: STORAGE_KEYS });
    });
};

// Запись переменной в хранилище
export const setStorageKey = (key, value) => async dispatch => {
    return new Promise(resolve => {
        const handler = async e => {
            e = e.detail;
            let found = false;
            switch (e.type) {
                case 'VKWebAppStorageSetResult':
                    found = true;
                    resolve(dispatch(setOneStorage(key, value)));
                    break;

                case 'VKWebAppStorageSetFailed':
                    found = true;
                    resolve(false);
                    break;

                default:
                    break;
            }
            if (found) {
                vkBridge.unsubscribe(handler);
            }
        };
        vkBridge.subscribe(handler);
        vkBridge.send('VKWebAppStorageSet', { key, value });
    });
};

// Ресайзим фрейм
export const resizeFrame = (offset = 0) => {
    const resize = () => {
        const height = document.querySelector('#root #h-container').getBoundingClientRect().height + offset;
        vkBridge.send('VKWebAppResizeWindow', { height });
    };

    setTimeout(resize, 1);
};

// Добавление в левое меню
export const addToLeftMenu = () => async dispatch => {
    return new Promise(resolve => {
        const handler = async e => {
            e = e.detail;
            let found = false;
            switch (e.type) {
                case 'VKWebAppAddToMenuResult':
                    found = true;
                    resolve(true);
                    break;

                case 'VKWebAppAddToMenuFailed':
                    found = true;
                    resolve(false);
                    break;

                default:
                    break;
            }
            if (found) {
                vkBridge.unsubscribe(handler);
            }
        };
        vkBridge.subscribe(handler);
        vkBridge.send('VKWebAppAddToMenu', {});
    });
};

// Получаем паблики подлюченные к авторам
export const getCreatedAuthors = () => {
    return apiRequest(
        'education.getCreatedAuthors',
        {},
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Получает админские паблики
export const getGroups = () => {
    return apiRequest(
        'groups.get',
        { filter: 'admin', count: 1000, extended: 1, fields: 'members_count,photo_50,photo_100,photo_200' },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Полаем типы авторов
export const getAuthorTypes = () => {
    return apiRequest(
        'education.getAuthorTypes',
        {},
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Создаем автора
export const createAuthor = (authorId, authorType) => {
    return apiRequest(
        'education.createAuthor',
        { author_id: authorId, type: authorType },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Получаем сервер для аплоада аватара
export const getAvatarUploadServer = authorId => {
    return apiRequest(
        'photos.getEducationCourseAvatarUploadServer',
        { author_id: authorId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Получаем сервер для аплоада обложки
export const getCoverUploadServer = authorId => {
    return apiRequest(
        'photos.getEducationCourseCoverUploadServer',
        { author_id: authorId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Получаем сервер для аплоада картинки
export const getImageUploadServer = authorId => {
    return apiRequest(
        'photos.getEducationQuestionImageUploadServer',
        { author_id: authorId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Сохраняем аватар
export const saveCourseAvatar = (authorId, photo, hash) => {
    return apiRequest(
        'photos.saveEducationCourseAvatar',
        { author_id: authorId, photo, hash },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Сохраняем обложку
export const saveCourseCover = (authorId, photo, hash) => {
    return apiRequest(
        'photos.saveEducationCourseCover',
        { author_id: authorId, photo, hash },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Сохраняем картинку
export const saveCourseImage = (authorId, photo, hash) => {
    return apiRequest(
        'photos.saveEducationQuestionImage',
        { author_id: authorId, photo, hash },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Получаем курсы
export const getAuthorCourses = (authorId, tabIndex, pageNum, requestId) => {
    const count = COURSES_PER_PAGE;
    const offset = (pageNum - 1) * count;

    let marathonsType;
    switch (tabIndex) {
        default:
        case 1:
            marathonsType = 'published';
            break;
        case 2:
            marathonsType = 'draft';
            break;
        case 3:
            marathonsType = 'archived';
            break;
    }

    return apiRequest(
        'education.getAuthorCourses',
        { author_id: authorId, type: marathonsType, offset, count },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
        requestId,
    );
};

// Получаем категории марафона
export const getCategories = () => {
    return apiRequest(
        'education.getCategories',
        {},
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Получаем URL видео
export const getVideoUrl = (requestId, ownerId, videoId) => {
    return apiRequest(
        'education.getVideoUrl',
        { author_id: ownerId, video_item_id: videoId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
        requestId,
    );
};

// Создаем курс
export const createCourse = (ownerId, title, description, categoryId, avatarPhotoId, coverPhotoId, videoId) => {
    const params = {
        author_id: ownerId,
        title,
        description,
        category_id: categoryId,
        avatar_photo_id: avatarPhotoId,
        cover_photo_id: coverPhotoId,
    };
    if (videoId !== 0) {
        params.video_item_id = videoId;
    }

    return apiRequest(
        'education.createCourse',
        params,
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Обновляем курс
export const updateCourse = (
    courseId,
    ownerId,
    title,
    description,
    categoryId,
    avatarPhotoId = undefined,
    coverPhotoId = undefined,
    videoId = undefined,
) => {
    const params = { course_id: courseId, author_id: ownerId, title, description, category_id: categoryId };
    if (coverPhotoId !== undefined) {
        params.cover_photo_id = coverPhotoId;
    }
    if (avatarPhotoId !== undefined) {
        params.avatar_photo_id = avatarPhotoId;
    }
    if (videoId !== undefined) {
        params.video_item_id = videoId;
    }

    return apiRequest(
        'education.updateCourse',
        params,
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Получаем шаги курса
export const getCourseSteps = (authorId, courseId, offset = 0, count = 42) => {
    return apiRequest(
        'education.getCourseSteps',
        { author_id: authorId, course_id: courseId, offset, count },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Создаем пустой шаг
export const createCourseStep = (authorId, courseId) => {
    return apiRequest(
        'education.createStep',
        { author_id: authorId, course_id: courseId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Получаем шаг курса
export const getCourseStep = (authorId, courseId, stepId) => {
    return apiRequest(
        'education.getCourseStep',
        { author_id: authorId, course_id: courseId, step_id: stepId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Публикуем курс
export const publishCourse = (authorId, courseId) => {
    return apiRequest(
        'education.publishCourse',
        { author_id: authorId, course_id: courseId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Помещаем курс в архив
export const archiveCourse = (authorId, courseId) => {
    return apiRequest(
        'education.archiveCourse',
        { author_id: authorId, course_id: courseId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Разархивируем курс
export const unarchiveCourse = (authorId, courseId) => {
    return apiRequest(
        'education.unarchiveCourse',
        { author_id: authorId, course_id: courseId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Удалить упражнение
export const deleteStep = (authorId, courseId, stepId) => {
    return apiRequest(
        'education.deleteStep',
        { author_id: authorId, course_id: courseId, step_id: stepId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Открыть редактор статьи
export const openArticleEditor = (ownerId, courseId, articleId) => {
    return new Promise(resolve => {
        const params = { owner_id: ownerId, course_id: courseId, article_id: articleId };
        const handler = async e => {
            e = e.detail;
            let found = false;
            switch (e.type) {
                case 'VKWebAppOpenArticleEditorResult':
                    found = true;
                    resolve(e);
                    break;

                default:
                    break;
            }
            if (found) {
                vkBridge.unsubscribe(handler);
            }
        };
        vkBridge.subscribe(handler);
        vkBridge.send('VKWebAppOpenArticleEditor', params);
    });
};

// Получить вопросы теста
export const getStepQuestions = (authorId, courseId, stepId, offset = 0, count = 42) => {
    return apiRequest(
        'education.getStepTestWithAnswer',
        { author_id: authorId, course_id: courseId, step_id: stepId, count, offset },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Добавить вопрос с текстом
export const addQuestionText = (authorId, courseId, stepId, text, answerText) => {
    return apiRequest(
        'education.addQuestionText',
        { author_id: authorId, course_id: courseId, step_id: stepId, text, answer_text: answerText },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Добавить вопрос с выбором
export const addQuestionSelect = (authorId, courseId, stepId, text, options) => {
    return apiRequest(
        'education.addQuestionSelect',
        { author_id: authorId, course_id: courseId, step_id: stepId, text, options: JSON.stringify(options) },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Добавить вопрос с соответствием
export const addQuestionMatch = (authorId, courseId, stepId, text, pairs) => {
    return apiRequest(
        'education.addQuestionMatch',
        { author_id: authorId, course_id: courseId, step_id: stepId, text, pairs: JSON.stringify(pairs) },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Обновить вопрос с текстом
export const updateQuestionText = (questionId, authorId, courseId, stepId, text, answerText) => {
    return apiRequest(
        'education.updateQuestionText',
        {
            question_id: questionId,
            author_id: authorId,
            course_id: courseId,
            step_id: stepId,
            text,
            answer_text: answerText,
        },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Добавить новые вопросы с соответствием
export const updateQuestionMatchDeleteAddEdit = (
    questionId,
    authorId,
    courseId,
    stepId,
    text,
    pairsDelete,
    pairsAdd,
    pairsEdit,
) => {
    const params = {
        question_id: questionId,
        author_id: authorId,
        course_id: courseId,
        step_id: stepId,
        text,
    };

    if (pairsDelete !== undefined) {
        params.delete_pairs = pairsDelete.join(',');
    }

    if (pairsAdd !== undefined) {
        params.add_pairs = JSON.stringify(pairsAdd);
    }

    if (pairsEdit !== undefined) {
        params.edit_pairs = JSON.stringify(pairsEdit);
    }

    return apiRequest(
        'education.updateQuestionMatch',
        params,
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Добавить новые вопросы с выбором
export const updateQuestionSelectDeleteAddEdit = (
    questionId,
    authorId,
    courseId,
    stepId,
    text,
    optionsDelete,
    optionsAdd,
    optionsEdit,
) => {
    const params = {
        question_id: questionId,
        author_id: authorId,
        course_id: courseId,
        step_id: stepId,
        text,
    };

    if (optionsDelete !== undefined) {
        params.delete_options = optionsDelete.join(',');
    }

    if (optionsAdd !== undefined) {
        params.add_options = JSON.stringify(optionsAdd);
    }

    if (optionsEdit !== undefined) {
        params.edit_options = JSON.stringify(optionsEdit);
    }

    return apiRequest(
        'education.updateQuestionSelect',
        params,
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Получить 1 вопрос
export const getStepQuestionWithAnswer = (authorId, courseId, stepId, questionId) => {
    return apiRequest(
        'education.getStepQuestionWithAnswer',
        { author_id: authorId, course_id: courseId, step_id: stepId, question_id: questionId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Удалить вопрос
export const deleteStepQuestion = (authorId, courseId, stepId, questionId) => {
    return apiRequest(
        'education.deleteStepQuestion',
        { author_id: authorId, course_id: courseId, step_id: stepId, question_id: questionId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

// Удалить тест
export const deleteTest = (authorId, courseId, stepId) => {
    return apiRequest(
        'education.deleteStepTest',
        { author_id: authorId, course_id: courseId, step_id: stepId },
        data => {
            return { result: 'success', data };
        },
        e => {
            return { result: 'fail', e };
        },
    );
};

export const apiRequest = (
    method,
    params = {},
    successCallback = undefined,
    failCallback = undefined,
    customRequestId = undefined,
) => {
    const requestId = customRequestId !== undefined ? customRequestId : getNewRequestId();

    return new Promise((resolve, reject) => {
        const handler = async e => {
            e = e.detail;
            let found = false;

            if (e.data !== undefined && e.data.request_id !== undefined && e.data.request_id === requestId) {
                switch (e.type) {
                    case 'VKWebAppCallAPIMethodResult':
                        found = true;
                        if (customRequestId === undefined) {
                            if (successCallback !== undefined) {
                                resolve(successCallback(e.data.response));
                            }
                            resolve(e.data.response);
                        } else {
                            if (successCallback !== undefined) {
                                resolve(successCallback({ response: e.data.response, request_id: e.data.request_id }));
                            }
                            resolve({ response: e.data.response, request_id: e.data.request_id });
                        }
                        break;

                    case 'VKWebAppCallAPIMethodFailed':
                        found = true;
                        if (customRequestId === undefined) {
                            if (failCallback !== undefined) {
                                resolve(failCallback(e.data));
                            }
                            resolve(e.data);
                        } else {
                            if (failCallback !== undefined) {
                                resolve(failCallback({ response: e.data.response, request_id: e.data.request_id }));
                            }
                            resolve({ response: e.data.response, request_id: e.data.request_id });
                        }
                        break;

                    default:
                        break;
                }
                if (found) {
                    vkBridge.unsubscribe(handler);
                }
            }
        };

        vkBridge.subscribe(handler);

        params.access_token = store.getState().vk.accessToken;

        if (params.v === undefined) {
            params.v = API_VERSION;
        }

        vkBridge.send('VKWebAppCallAPIMethod', {
            method,
            params,
            request_id: requestId,
        });
    });
};
