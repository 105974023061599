import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormInputDel from './FormInputDel';
import { testAddNewMatchAnswer, testSetMatchAnswerRight } from '../store/test/actions';
import { resizeFrame } from '../services/vk';

const MarathonAnswerMatch = ({ qNum, aNum, textLeft, textRight, answersTotal, isLast }) => {
    const dispatch = useDispatch();
    const marathonStatus = useSelector(state => state.create.marathonStatus);

    const changeRight = e => {
        dispatch(testSetMatchAnswerRight(qNum, aNum, e.target.value));
    };

    const createNewAnswer = () => {
        if (isLast && marathonStatus === 'draft' && answersTotal < 8) {
            dispatch(testAddNewMatchAnswer(qNum));
        }
        resizeFrame();
    };

    return (
        <div className="test--pairs">
            <FormInputDel
                qNum={qNum}
                aNum={aNum}
                text={textLeft}
                isLast={isLast}
                isMatch
                placeholder="Введите первую часть"
                answersTotal={answersTotal}
            />
            <input
                placeholder="Введите вторую часть"
                value={textRight}
                onChange={changeRight}
                onClick={createNewAnswer}
                maxLength={64}
            />
        </div>
    );
};

MarathonAnswerMatch.propTypes = {
    qNum: PropTypes.number,
    aNum: PropTypes.number,
    textLeft: PropTypes.string,
    textRight: PropTypes.string,
    isLast: PropTypes.bool,
    answersTotal: PropTypes.number,
};

export default MarathonAnswerMatch;
