import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import { closeModal } from '../store/globals/actions';
import '../../styles/modal.scss';
import '../../styles/modal-del.scss';

const ModalDel = ({ title, deleteFunc, top, bottom }) => {
    const dispatch = useDispatch();

    const closeModalOverlay = e => {
        if (e.target.classList.contains('modal--overlay')) {
            dispatch(closeModal(''));
        }
    };

    const closeMdl = () => {
        dispatch(closeModal(null));
    };

    const deleteAction = () => {
        closeMdl();
        deleteFunc();
    };

    const style =
        top !== undefined
            ? { top, transform: 'translateX(-50%)' }
            : bottom !== undefined
            ? { bottom, top: 'auto', transform: 'translateX(-50%)' }
            : null;

    return (
        <div className="modal--overlay" onClick={e => closeModalOverlay(e)}>
            <div className="modal--content" style={style}>
                <div className="modal-del">
                    <div className="modal-del--header">
                        <span>Удалить {title}</span>
                        <div className="modal--close" onClick={closeMdl}>
                            <Icon24Cancel fill="fff" />
                        </div>
                    </div>
                    <div className="modal-del--body">
                        Вы собираетесь удалить {title}.<br />
                        Вся информация будет безвозвратно уничтожена. Вы уверены?
                    </div>
                    <div className="modal-del--footer">
                        <Button mode="tertiary" onClick={closeMdl}>
                            Отмена
                        </Button>
                        <Button onClick={deleteAction}>Удалить</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ModalDel.propTypes = {
    deleteFunc: PropTypes.func,
    title: PropTypes.string,
    top: PropTypes.number,
    bottom: PropTypes.number,
};

export default ModalDel;
