import React from 'react';
import PropTypes from 'prop-types';
import Icon24Dropdown from '@vkontakte/icons/dist/24/dropdown';
import '../../styles/dropdown.scss';

const Dropdown = ({ title, sizeIcon, children, onClickAva }) => {
    return (
        <div className="dropdown">
            <div className="dropdown--label" onClick={onClickAva}>
                <span>{title}</span> <Icon24Dropdown width={sizeIcon} height={sizeIcon} fill="var(--icon_tertiary)" />
            </div>
            <div className="dropdown--items">{children}</div>
        </div>
    );
};

Dropdown.propTypes = {
    title: PropTypes.object,
    sizeIcon: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    onClickAva: PropTypes.func,
};

export default Dropdown;
