import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@vkontakte/vkui/dist/components/Radio/Radio';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import {
    addQuestionMatch,
    addQuestionSelect,
    addQuestionText,
    deleteStepQuestion,
    getStepQuestionWithAnswer,
    resizeFrame,
    updateQuestionMatchDeleteAddEdit,
    updateQuestionSelectDeleteAddEdit,
    updateQuestionText,
} from '../services/vk';
import { openModal } from '../store/globals/actions';
import {
    testRemoveQuestion,
    testSetQuestion,
    testSetQuestionId,
    testSetQuestionTitle,
    testSetQuestionType,
} from '../store/test/actions';
import { copyArray } from '../services/_functions';
import ModalDel from './ModalDel';
import FormLabel from './FormLabel';
import FormGroup from './FormGroup';
import MarathonAnswerSelect from './MarathonAnswerSelect';
import MarathonAnswerMatch from './MarathonAnswerMatch';
import MarathonAnswerText from './MarathonAnswerText';
import ModalError from './ModalError';
import ButtonWithLoader from './ButtonWithLoader';

const MarathonTestQuestion = ({ id, qNum, type, title, answers, answersDelete, answersEdit }) => {
    const dispatch = useDispatch();
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const selectedCourseId = useSelector(state => state.create.selectedCourseId);
    const currentStep = useSelector(state => state.test.currentStep);
    const marathonStatus = useSelector(state => state.create.marathonStatus);
    const [isSavingQuestion, setIsSavingQuestion] = useState(false);
    const elementRef = useRef(null);

    const changeQuestionText = e => {
        dispatch(testSetQuestionTitle(qNum, e.target.value));
    };

    const changeQuestionType = qType => {
        dispatch(testSetQuestionType(qNum, qType));
        resizeFrame();
    };

    const renderAnswers = () => {
        const answersRender = [];
        switch (type) {
            case 'select':
                answers.forEach((a, i) => {
                    answersRender.push(
                        <MarathonAnswerSelect
                            id={a.id}
                            key={`as-${qNum}-${i}`}
                            qNum={qNum}
                            aNum={i}
                            text={a.text}
                            isRight={a.is_answer !== undefined ? a.is_answer : false}
                            isLast={i === answers.length - 1}
                            answersTotal={answers.length}
                        />,
                    );
                });
                break;
            case 'text':
                answersRender.push(<MarathonAnswerText key={`at-${qNum}`} qNum={qNum} text={answers} />);
                break;
            case 'match':
                answers.forEach((a, i) => {
                    answersRender.push(
                        <MarathonAnswerMatch
                            key={`am-${qNum}-${i}`}
                            qNum={qNum}
                            aNum={i}
                            textLeft={a[0]}
                            textRight={a[1]}
                            isLast={i === answers.length - 1}
                            answersTotal={answers.length}
                        />,
                    );
                });
                break;
        }

        return answersRender;
    };

    const deleteQuestion = async () => {
        if (process.env.NODE_ENV === 'production') {
            if (id !== 0) {
                const deleteQuestionResult = await deleteStepQuestion(
                    lastSelectedPublic,
                    selectedCourseId,
                    currentStep,
                    id,
                );

                if (deleteQuestionResult.result === 'success') {
                    dispatch(testRemoveQuestion(qNum));
                }
            } else {
                dispatch(testRemoveQuestion(qNum));
            }
        } else {
            dispatch(testRemoveQuestion(qNum));
        }
        resizeFrame();
    };

    const openModalDel = () => {
        dispatch(
            openModal(
                <ModalDel
                    title="вопрос"
                    deleteFunc={deleteQuestion}
                    top={elementRef.current.getBoundingClientRect().top + 100}
                />,
            ),
        );
    };

    const saveQuestion = async () => {
        setIsSavingQuestion(true);
        let isSuccess = false;
        let qId = id;

        if (title === '') {
            dispatch(
                openModal(
                    <ModalError
                        title="Введите название вопроса"
                        top={elementRef.current.getBoundingClientRect().top + 100}
                    />,
                ),
            );
            setIsSavingQuestion(false);
            return;
        }

        switch (type) {
            case 'select':
                if (id === 0) {
                    let hasAnswer = false;
                    prepareCreateArray(answers, false).forEach(a => {
                        if (a.is_answer !== undefined && a.is_answer === true) {
                            hasAnswer = true;
                        }
                    });

                    if (!hasAnswer) {
                        dispatch(
                            openModal(
                                <ModalError
                                    title="Выберите хотя бы 1 правильный ответ"
                                    top={elementRef.current.getBoundingClientRect().top + 100}
                                />,
                            ),
                        );
                        setIsSavingQuestion(false);
                        return;
                    }

                    // Вопрос еще не сохранен, создаем его
                    const addQuestionSelectResult = await addQuestionSelect(
                        lastSelectedPublic,
                        selectedCourseId,
                        currentStep,
                        title,
                        prepareCreateArray(answers, false),
                    );
                    if (addQuestionSelectResult.result === 'success') {
                        isSuccess = true;
                        qId = addQuestionSelectResult.data.question_id;
                        dispatch(testSetQuestionId(qNum, addQuestionSelectResult.data.question_id));
                    } else {
                        dispatch(
                            openModal(
                                <ModalError
                                    title="Произошла ошибка, из-за которой не удалось сохранить вопрос. Попробуйте еще раз."
                                    top={elementRef.current.getBoundingClientRect().top + 100}
                                />,
                            ),
                        );
                    }
                } else {
                    let hasAnswer = false;
                    prepareCreateArray(answers, false).forEach(a => {
                        if (a.is_answer !== undefined && a.is_answer === true) {
                            hasAnswer = true;
                        }
                    });

                    if (!hasAnswer) {
                        dispatch(
                            openModal(
                                <ModalError
                                    title="Выберите хотя бы 1 правильный ответ"
                                    top={elementRef.current.getBoundingClientRect().top + 100}
                                />,
                            ),
                        );
                        setIsSavingQuestion(false);
                        return;
                    }

                    // Вопрос уже создан, сохраняем
                    if (marathonStatus === 'draft') {
                        // Если в черновиках
                        const updateQuestionSelectResult = await updateQuestionSelectDeleteAddEdit(
                            id,
                            lastSelectedPublic,
                            selectedCourseId,
                            currentStep,
                            title,
                            answersDelete,
                            prepareCreateArray(answers, false),
                            answersEdit,
                        );
                        if (updateQuestionSelectResult.result === 'success') {
                            isSuccess = true;
                        } else {
                            dispatch(
                                openModal(
                                    <ModalError
                                        title="Произошла ошибка, из-за которой не удалось сохранить вопрос. Попробуйте еще раз."
                                        top={elementRef.current.getBoundingClientRect().top + 100}
                                    />,
                                ),
                            );
                        }
                    } else {
                        // Если опубликован или в архиве
                        const updateQuestionSelectResult = await updateQuestionSelectDeleteAddEdit(
                            id,
                            lastSelectedPublic,
                            selectedCourseId,
                            currentStep,
                            title,
                            undefined,
                            undefined,
                            answersEdit,
                        );
                        if (updateQuestionSelectResult.result === 'success') {
                            isSuccess = true;
                        } else {
                            dispatch(
                                openModal(
                                    <ModalError
                                        title="Произошла ошибка, из-за которой не удалось сохранить вопрос. Попробуйте еще раз."
                                        top={elementRef.current.getBoundingClientRect().top + 100}
                                    />,
                                ),
                            );
                        }
                    }
                }
                break;

            case 'text':
                if (id === 0) {
                    const addQuestionTextResult = await addQuestionText(
                        lastSelectedPublic,
                        selectedCourseId,
                        currentStep,
                        title,
                        answers,
                    );
                    if (addQuestionTextResult.result === 'success') {
                        isSuccess = true;
                        qId = addQuestionTextResult.data.question_id;
                        dispatch(testSetQuestionId(qNum, addQuestionTextResult.data.question_id));
                    } else {
                        dispatch(
                            openModal(
                                <ModalError
                                    title="Произошла ошибка, из-за которой не удалось сохранить вопрос. Попробуйте еще раз."
                                    top={elementRef.current.getBoundingClientRect().top + 100}
                                />,
                            ),
                        );
                    }
                } else {
                    const updateQuestionTextResult = await updateQuestionText(
                        id,
                        lastSelectedPublic,
                        selectedCourseId,
                        currentStep,
                        title,
                        answers,
                    );
                    if (updateQuestionTextResult.result === 'success') {
                        isSuccess = true;
                    } else {
                        dispatch(
                            openModal(
                                <ModalError
                                    title="Произошла ошибка, из-за которой не удалось сохранить вопрос. Попробуйте еще раз."
                                    top={elementRef.current.getBoundingClientRect().top + 100}
                                />,
                            ),
                        );
                    }
                }
                break;

            case 'match':
                if (id === 0) {
                    // Вопрос еще не сохранен, создаем его
                    const addQuestionMatchResult = await addQuestionMatch(
                        lastSelectedPublic,
                        selectedCourseId,
                        currentStep,
                        title,
                        prepareCreateArray(answers, true),
                    );
                    if (addQuestionMatchResult.result === 'success') {
                        isSuccess = true;
                        qId = addQuestionMatchResult.data.question_id;
                        dispatch(testSetQuestionId(qNum, addQuestionMatchResult.data.question_id));
                    } else {
                        dispatch(
                            openModal(
                                <ModalError
                                    title="Произошла ошибка, из-за которой не удалось сохранить вопрос. Попробуйте еще раз."
                                    top={elementRef.current.getBoundingClientRect().top + 100}
                                />,
                            ),
                        );
                    }
                } else {
                    // Вопрос уже создан, сохраняем
                    if (marathonStatus === 'draft') {
                        // Если в черновиках
                        const updateQuestionMatchResult = await updateQuestionMatchDeleteAddEdit(
                            id,
                            lastSelectedPublic,
                            selectedCourseId,
                            currentStep,
                            title,
                            answersDelete,
                            prepareCreateArray(answers, true),
                            answersEdit,
                        );
                        if (updateQuestionMatchResult.result === 'success') {
                            isSuccess = true;
                        } else {
                            dispatch(
                                openModal(
                                    <ModalError
                                        title="Произошла ошибка, из-за которой не удалось сохранить вопрос. Попробуйте еще раз."
                                        top={elementRef.current.getBoundingClientRect().top + 100}
                                    />,
                                ),
                            );
                        }
                    } else {
                        // Если опубликован или в архиве
                        const updateQuestionMatchResult = await updateQuestionMatchDeleteAddEdit(
                            id,
                            lastSelectedPublic,
                            selectedCourseId,
                            currentStep,
                            title,
                            undefined,
                            undefined,
                            answersEdit,
                        );
                        if (updateQuestionMatchResult.result === 'success') {
                            isSuccess = true;
                        } else {
                            dispatch(
                                openModal(
                                    <ModalError
                                        title="Произошла ошибка, из-за которой не удалось сохранить вопрос. Попробуйте еще раз."
                                        top={elementRef.current.getBoundingClientRect().top + 100}
                                    />,
                                ),
                            );
                        }
                    }
                }
                break;
        }

        if (isSuccess) {
            const getQuestionsResult = await getStepQuestionWithAnswer(
                lastSelectedPublic,
                selectedCourseId,
                currentStep,
                qId,
            );
            if (getQuestionsResult.result === 'success') {
                dispatch(testSetQuestion(getQuestionsResult.data.items[0], qNum));
                resizeFrame();
            }
        }

        setIsSavingQuestion(false);
    };

    const prepareCreateArray = (arr, isMatch) => {
        const newArray = copyArray(arr);
        const splicedArray = [];
        newArray.forEach((a, i) => {
            if (isMatch) {
                if (newArray[i][0] !== '' && newArray[i][1] !== '' && newArray[i][2] === 0) {
                    splicedArray.push([newArray[i][0], newArray[i][1]]);
                }
            } else {
                if (newArray[i].text !== '' && newArray[i].id === 0) {
                    splicedArray.push(newArray[i]);
                }
            }
        });

        return splicedArray;
    };

    return (
        <div className="test--inner" ref={elementRef}>
            <div className="test--block">
                {id !== 0 && marathonStatus === 'draft' && (
                    <div className="delete-question" onClick={openModalDel}>
                        <Icon16Cancel fill="aeb7c2" />
                    </div>
                )}
                <FormGroup>
                    <FormLabel title="Вопрос">
                        <input
                            placeholder="Введите вопрос"
                            value={title}
                            onChange={changeQuestionText}
                            maxLength={256}
                        />
                    </FormLabel>
                    <FormLabel title="Тип вопроса">
                        <FormLayout>
                            <Radio
                                disabled={id !== 0}
                                name={`r${qNum}`}
                                className="radio-custom"
                                checked={type === 'select'}
                                onChange={() => changeQuestionType('select')}
                            >
                                Выбор ответа
                            </Radio>
                            <Radio
                                disabled={id !== 0}
                                name={`r${qNum}`}
                                className="radio-custom"
                                checked={type === 'text'}
                                onChange={() => changeQuestionType('text')}
                            >
                                Ввод текста
                            </Radio>
                            <Radio
                                disabled={id !== 0}
                                name={`r${qNum}`}
                                className="radio-custom"
                                checked={type === 'match'}
                                onChange={() => changeQuestionType('match')}
                            >
                                Соедините пары
                            </Radio>
                        </FormLayout>
                    </FormLabel>
                    <FormLabel title={type === 'text' ? 'Ответ' : 'Варианты ответов'}>{renderAnswers()}</FormLabel>
                </FormGroup>
                <ButtonWithLoader mode="secondary" onClickFunc={saveQuestion} isLoading={isSavingQuestion}>
                    Сохранить вопрос
                </ButtonWithLoader>
            </div>
        </div>
    );
};

MarathonTestQuestion.propTypes = {
    id: PropTypes.number,
    qNum: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    answers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    answersDelete: PropTypes.array,
    answersEdit: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default MarathonTestQuestion;
