import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import '@vkontakte/vkui/dist/vkui.css';
import { getAuthorTypes, getAuthToken, getCreatedAuthors, getGroups, getStorage, initApp } from './js/services/vk';
import { getAppId, sleep } from './js/services/_functions';
import { setAppId, setAuthTokenSuccess } from './js/store/vk/actions';
import { authorTypesMock, marathonedPublicsArrMock, publicsArrMock, storageMock, vkUserMock } from './js/services/mock';
import { setStorage } from './js/store/storage/actions';
import Routing from './js/classes/Routing';
import './styles/style.scss';
import {
    authorSetMarathonedPublics,
    authorSetPublics,
    authorSetTypes,
    authorUpdateFilteredPublics,
    authorUpdateMarathonedFilledPublics,
} from './js/store/author/actions';
import { setStartInfoLoaded } from './js/store/globals/actions';

const App = ({ pageId }) => {
    const dispatch = useDispatch();
    const modal = useSelector(state => state.globals.modal);
    const accessToken = useSelector(state => state.vk.accessToken);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            dispatch(setAuthTokenSuccess('token_mock'));
            dispatch(setStorage(storageMock()));
            const groups = publicsArrMock();
            dispatch(authorSetPublics(groups));
            const marathonedPublics = marathonedPublicsArrMock();
            dispatch(authorSetMarathonedPublics(marathonedPublics.items));
            dispatch(authorUpdateFilteredPublics());
            dispatch(authorUpdateMarathonedFilledPublics());
            dispatch(authorSetTypes(authorTypesMock().types));

            // Если пользователь еще не создал ниодного марафона
            if (marathonedPublics.count === 0) {
                dispatch(push('/welcome'));
            } else {
                dispatch(push('/marathons'));
            }

            // Только для тестов
            dispatch(push('/marathons'));

            dispatch(setStartInfoLoaded());
        } else {
            (async () => {
                await dispatch(setAppId(getAppId()));
                const getTokenPromise = dispatch(getAuthToken());
                const getStoragePromise = dispatch(getStorage());
                await Promise.all([getTokenPromise, getStoragePromise]);
            })();
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (accessToken !== 0) {
                // Получаем все группы
                let isEndReached = false;
                let groups = [];
                let offset = 0;
                while (!isEndReached) {
                    const groupsResult = await getGroups(offset);
                    if (groupsResult.result === 'success') {
                        groups = [...groups, ...groupsResult.data.items];
                        offset += 1000;

                        if (offset >= groupsResult.data.count) {
                            isEndReached = true;
                        }
                    }

                    if (!isEndReached) {
                        await sleep(333);
                    }
                }
                dispatch(authorSetPublics(groups));

                // Получаем созданных авторов
                const createdAuthor = await getCreatedAuthors();
                dispatch(authorSetMarathonedPublics(createdAuthor.data.items));

                // Обновляем список еще не созданных пабликов
                dispatch(authorUpdateFilteredPublics());

                // Обновляем список созданных авторских пабликов
                dispatch(authorUpdateMarathonedFilledPublics());

                // Получаем список типов авторов
                const authorTypes = await getAuthorTypes();
                dispatch(authorSetTypes(authorTypes.data.types));

                // Если пользователь еще не создал ниодного марафона
                if (createdAuthor.data.count === 0) {
                    dispatch(push('/welcome'));
                } else {
                    dispatch(push('/marathons'));
                }

                dispatch(setStartInfoLoaded());
                await initApp();
            }
        })();
    }, [accessToken]);

    const activePanel = Routing().getPanel(pageId);

    return (
        <div id="h-container">
            {activePanel}
            {modal}
        </div>
    );
};

App.propTypes = {
    pageId: PropTypes.string,
};

App.defaultProps = {
    pageId: '',
};

export default App;
