import * as types from './actionTypes';
import { copyArray } from '../../services/_functions';

const optionTemplate = {
    text: '',
    id: 0,
    is_answer: undefined,
};

const pairTemplate = ['', '', 0, 0];

const questionTemplate = {
    type: 'select',
    question_id: 0,
    text: '',
    options: [copyArray(optionTemplate)],
    optionsDelete: [],
    optionsEdit: {},
    answer_text: '',
    pairs: [copyArray(pairTemplate)],
    pairsDelete: [],
    pairsEdit: {},
};

const initialState = {
    questions: [],
    currentStep: 0,
};

export const test = (state = initialState, action) => {
    switch (action.type) {
        case types.TEST_SET_QUESTIONS: {
            const newQuestions = [];
            action.questions.forEach(q => {
                const qqq = copyArray(questionTemplate);
                qqq.question_id = q.question_id;
                qqq.type = q.type;
                qqq.text = q.text;

                if (qqq.type === 'text') {
                    qqq.answer_text = q.answer_text;
                }

                if (qqq.type === 'select') {
                    const newOptions = [];
                    q.options.forEach(o => {
                        let isAnswer;
                        q.answer_ids.forEach(aid => {
                            if (aid === o.id) {
                                isAnswer = true;
                            }
                        });
                        const opt = { text: o.text, id: o.id };
                        if (isAnswer !== undefined) {
                            opt.is_answer = isAnswer;
                        }
                        newOptions.push(opt);
                    });
                    qqq.options = newOptions;
                }

                if (qqq.type === 'match') {
                    const newPairs = [];
                    q.pairs.forEach(p => {
                        newPairs.push([p.pair[0].text, p.pair[1].text, p.pair[0].id, p.pair[1].id]);
                    });
                    qqq.pairs = newPairs;
                }

                newQuestions.push(qqq);
            });

            if (newQuestions.length === 0) {
                newQuestions.push(copyArray(questionTemplate));
            }

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_ADD_NEW_QUESTION: {
            const newQuestions = copyArray(state.questions);
            newQuestions.push(copyArray(questionTemplate));

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_REMOVE_QUESTION: {
            const newQuestions = copyArray(state.questions).filter((q, n) => n !== action.qNum);
            if (newQuestions.length === 0) {
                newQuestions.push(copyArray(questionTemplate));
            }
            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_SET_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.currentStep,
            };

        case types.TEST_SET_QUESTION_TITLE: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].text = action.title;

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_SET_QUESTION_TYPE: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].type = action.qType;

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_SET_QUESTION_ID: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].question_id = action.qId;

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_SET_TEXT_ANSWER: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].answer_text = action.answerText;

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_SET_SELECT_ANSWER_TEXT: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].options[action.aNum].text = action.text;

            if (newQuestions[action.qNum].options[action.aNum].id !== 0) {
                const optionsEdit = copyArray(state.questions[action.qNum].optionsEdit);
                optionsEdit[newQuestions[action.qNum].options[action.aNum].id] = {
                    text: action.text,
                    is_answer: newQuestions[action.qNum].options[action.aNum].is_answer,
                };
                newQuestions[action.qNum].optionsEdit = optionsEdit;
            }

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_SET_SELECT_ANSWER_IS_RIGHT: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].options[action.aNum].is_answer = action.isAnswer;

            if (newQuestions[action.qNum].options[action.aNum].id !== 0) {
                const optionsEdit = copyArray(state.questions[action.qNum].optionsEdit);
                optionsEdit[newQuestions[action.qNum].options[action.aNum].id] = {
                    text: newQuestions[action.qNum].options[action.aNum].text,
                    is_answer: action.isAnswer === false ? undefined : action.isAnswer,
                };
                newQuestions[action.qNum].optionsEdit = optionsEdit;
            }

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_SET_MATCH_ANSWER_LEFT: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].pairs[action.aNum][0] = action.text;

            if (newQuestions[action.qNum].pairs[action.aNum][2] !== 0) {
                const pairsEdit = copyArray(state.questions[action.qNum].pairsEdit);
                pairsEdit[newQuestions[action.qNum].pairs[action.aNum][2]] = [
                    action.text,
                    newQuestions[action.qNum].pairs[action.aNum][1],
                ];
                newQuestions[action.qNum].pairsEdit = pairsEdit;
            }

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_SET_MATCH_ANSWER_RIGHT: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].pairs[action.aNum][1] = action.text;

            if (newQuestions[action.qNum].pairs[action.aNum][2] !== 0) {
                const pairsEdit = copyArray(state.questions[action.qNum].pairsEdit);
                pairsEdit[newQuestions[action.qNum].pairs[action.aNum][2]] = [
                    newQuestions[action.qNum].pairs[action.aNum][0],
                    action.text,
                ];
                newQuestions[action.qNum].pairsEdit = pairsEdit;
            }

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_ADD_NEW_SELECT_ANSWER: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].options.push(copyArray(optionTemplate));

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_ADD_NEW_MATCH_ANSWER: {
            const newQuestions = copyArray(state.questions);
            newQuestions[action.qNum].pairs.push(copyArray(pairTemplate));

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_DELETE_SELECT_ANSWER: {
            const newQuestions = copyArray(state.questions);
            const deleteOptions = copyArray(state.questions[action.qNum].optionsDelete);
            const editOptions = copyArray(state.questions[action.qNum].optionsEdit);

            if (newQuestions[action.qNum].options[action.aNum].id !== 0) {
                deleteOptions.push(newQuestions[action.qNum].options[action.aNum].id);
                newQuestions[action.qNum].optionsDelete = deleteOptions;

                delete editOptions[newQuestions[action.qNum].options[action.aNum].id];
                newQuestions[action.qNum].optionsEdit = editOptions;
            }

            newQuestions[action.qNum].options = newQuestions[action.qNum].options.filter((a, n) => n !== action.aNum);
            if (newQuestions[action.qNum].options.length === 0) {
                newQuestions[action.qNum].options.push(copyArray(optionTemplate));
            }

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_DELETE_MATCH_ANSWER: {
            const newQuestions = copyArray(state.questions);
            const deletePairs = copyArray(state.questions[action.qNum].pairsDelete);
            const editPairs = copyArray(state.questions[action.qNum].pairsEdit);

            if (newQuestions[action.qNum].pairs[action.aNum][2] !== 0) {
                deletePairs.push(newQuestions[action.qNum].pairs[action.aNum][2]);
                newQuestions[action.qNum].pairsDelete = deletePairs;

                delete editPairs[newQuestions[action.qNum].pairs[action.aNum][2]];
                newQuestions[action.qNum].pairsEdit = editPairs;
            }

            newQuestions[action.qNum].pairs = newQuestions[action.qNum].pairs.filter((p, n) => n !== action.aNum);
            if (newQuestions[action.qNum].pairs.length === 0) {
                newQuestions[action.qNum].pairs.push(copyArray(pairTemplate));
            }

            return {
                ...state,
                questions: newQuestions,
            };
        }

        case types.TEST_RESET:
            return {
                ...state,
                questions: [],
                currentStep: 0,
            };

        case types.TEST_SET_QUESTION: {
            const newQuestions = copyArray(state.questions);

            const qqq = copyArray(questionTemplate);
            qqq.question_id = action.question.question_id;
            qqq.type = action.question.type;
            qqq.text = action.question.text;

            if (qqq.type === 'text') {
                qqq.answer_text = action.question.answer_text;
            }

            if (qqq.type === 'select') {
                const newOptions = [];
                action.question.options.forEach(o => {
                    let isAnswer;
                    action.question.answer_ids.forEach(aid => {
                        if (aid === o.id) {
                            isAnswer = true;
                        }
                    });
                    const opt = { text: o.text, id: o.id };
                    if (isAnswer !== undefined) {
                        opt.is_answer = isAnswer;
                    }
                    newOptions.push(opt);
                });
                qqq.options = newOptions;
            }

            if (qqq.type === 'match') {
                const newPairs = [];
                action.question.pairs.forEach(p => {
                    newPairs.push([p.pair[0].text, p.pair[1].text, p.pair[0].id, p.pair[1].id]);
                });
                qqq.pairs = newPairs;
            }

            newQuestions[action.qNum] = qqq;

            return {
                ...state,
                questions: newQuestions,
            };
        }

        default:
            return state;
    }
};
