import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import {
    testAddNewMatchAnswer,
    testAddNewSelectAnswer,
    testDeleteMatchAnswer,
    testDeleteSelectAnswer,
    testSetMatchAnswerLeft,
    testSetSelectAnswerText,
} from '../store/test/actions';
import { resizeFrame } from '../services/vk';

const FormInputDel = ({ id, aNum, qNum, placeholder, answersTotal, text, isMatch, isLast }) => {
    const dispatch = useDispatch();
    const marathonStatus = useSelector(state => state.create.marathonStatus);

    const editText = e => {
        if (isMatch) {
            dispatch(testSetMatchAnswerLeft(qNum, aNum, e.target.value));
        } else {
            dispatch(testSetSelectAnswerText(qNum, aNum, e.target.value));
        }
    };

    const deleteLine = () => {
        if (isMatch) {
            dispatch(testDeleteMatchAnswer(qNum, aNum, id));
        } else {
            dispatch(testDeleteSelectAnswer(qNum, aNum, id));
        }
        resizeFrame();
    };

    const createNewAnswer = () => {
        if (isLast && marathonStatus === 'draft' && answersTotal < 8) {
            if (isMatch) {
                dispatch(testAddNewMatchAnswer(qNum));
            } else {
                dispatch(testAddNewSelectAnswer(qNum));
            }
            resizeFrame();
        }
    };

    return (
        <div className="input">
            <input
                placeholder={placeholder}
                value={text}
                onChange={editText}
                onFocus={createNewAnswer}
                maxLength={64}
            />
            {marathonStatus === 'draft' && (
                <div className="input--close" onClick={deleteLine}>
                    <Icon16Cancel fill="aeb7c2" />
                    <div className="input--tooltip">Удалить вариант</div>
                </div>
            )}
        </div>
    );
};

FormInputDel.propTypes = {
    id: PropTypes.number,
    aNum: PropTypes.number,
    qNum: PropTypes.number,
    placeholder: PropTypes.string,
    text: PropTypes.string,
    isMatch: PropTypes.bool,
    isLast: PropTypes.bool,
    answersTotal: PropTypes.number,
};

export default FormInputDel;
