import * as types from './actionTypes';

const initialState = {
    modal: null,
    isStartInfoLoaded: false,
    isDropdownOpened: false,
};

export const globals = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN_MODAL:
            document.body.classList.add('modal--open');
            return {
                ...state,
                modal: action.modal,
            };

        case types.CLOSE_MODAL:
            document.body.classList.remove('modal--open');
            return {
                ...state,
                modal: null,
            };

        case types.SET_START_INFO_LOADED: {
            return {
                ...state,
                isStartInfoLoaded: true,
            };
        }

        case types.SET_DROPDOWN_OPENED: {
            return {
                ...state,
                isDropdownOpened: action.isDropdownOpened,
            };
        }

        default:
            return state;
    }
};
