import * as types from './actionTypes';
import { COURSES_PER_PAGE } from '../../services/_globals';
import { copyArray } from '../../services/_functions';

const COURSES = {
    items: [],
    offset: 0,
    count: COURSES_PER_PAGE,
    isLoading: false,
    isLoaded: false,
    total: 0,
};

const initialState = {
    tabIndex: 1,
    pageNum: {
        1: 1,
        2: 1,
        3: 1,
    },
    courses: {
        1: copyArray(COURSES),
        2: copyArray(COURSES),
        3: copyArray(COURSES),
    },
};

export const marathons = (state = initialState, action) => {
    switch (action.type) {
        case types.MARATHONS_SET_TAB_INDEX:
            return {
                ...state,
                tabIndex: action.tabIndex,
            };

        case types.MARATHONS_SET_PAGE_NUM: {
            const newPageNum = { ...state.pageNum };
            newPageNum[action.tabIndex] = action.pageNum;

            return {
                ...state,
                pageNum: newPageNum,
            };
        }

        case types.MARATHONS_SET_COURSES: {
            const newCourses = { ...state.courses };
            newCourses[action.tabIndex].items = [...action.courses];
            newCourses[action.tabIndex].offset = newCourses[action.tabIndex].offset + newCourses[action.tabIndex].count;
            newCourses[action.tabIndex].total = action.total;
            newCourses[action.tabIndex].isLoaded = true;
            newCourses[action.tabIndex].isLoading = false;

            return {
                ...state,
                courses: { ...newCourses },
            };
        }

        case types.MARATHONS_SET_COURSES_IS_LOADING: {
            const newCourses = { ...state.courses };
            newCourses[action.tabIndex].isLoading = true;

            return {
                ...state,
                courses: { ...newCourses },
            };
        }

        case types.MARATHONS_SET_STOP_LOADING: {
            const newCourses = { ...state.courses };
            newCourses[action.tabIndex].isLoading = false;

            return {
                ...state,
                courses: { ...newCourses },
            };
        }

        case types.MARATHONS_RESET_TAB_PAGE_AND_COURSES: {
            return {
                ...state,
                tabIndex: 1,
                pageNum: {
                    1: 1,
                    2: 1,
                    3: 1,
                },
                courses: {
                    1: copyArray(COURSES),
                    2: copyArray(COURSES),
                    3: copyArray(COURSES),
                },
            };
        }

        case types.MARATHONS_CLEAR_COURSES_AND_PAGE_NUM: {
            const newPageNum = { ...state.pageNum };
            newPageNum[action.tabIndex] = 1;

            const newCourses = { ...state.courses };
            newCourses[action.tabIndex] = copyArray(COURSES);

            return {
                ...state,
                courses: { ...newCourses },
                pageNum: newPageNum,
            };
        }

        case types.MARATHONS_UPDATE_MARATHON: {
            let getTabIndex;
            switch (action.publishType) {
                case 'published':
                    getTabIndex = 1;
                    break;
                case 'draft':
                    getTabIndex = 2;
                    break;
                case 'archived':
                    getTabIndex = 3;
                    break;
            }

            const coursesToUpdate = copyArray(state.courses[getTabIndex]);

            const updatedCoursesItems = coursesToUpdate.items.map(ctu => {
                if (ctu.course_id === action.courseId) {
                    const courseToEdit = copyArray(ctu);
                    courseToEdit.title = action.title;
                    courseToEdit.description = action.description;
                    courseToEdit.avatar_photo[0].b.url = action.avatar;
                    courseToEdit.cover_photo[0].b.url = action.cover;
                    courseToEdit.categories[0].id = action.category;
                    courseToEdit.categories[0].name = action.categoryTranslate;
                    courseToEdit.video_item_id = action.videoId;
                    courseToEdit.video_url = action.videoPreviewUrl;

                    return courseToEdit;
                } else {
                    return ctu;
                }
            });

            const coursesAll = state.courses;
            coursesAll[getTabIndex].items = updatedCoursesItems;

            return {
                ...state,
                courses: { ...coursesAll },
            };
        }

        default:
            return state;
    }
};
