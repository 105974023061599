import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import Icon16Add from '@vkontakte/icons/dist/16/add';
import { setStorageKey } from '../services/vk';
import { setOneStorage } from '../store/storage/actions';
import { setDropdownOpened } from '../store/globals/actions';
import { marathonsResetTabPageAndCourses } from '../store/marathons/actions';
import { getMarathons } from '../services/_functions';
import '../../styles/custom-scroll.scss';

const DropdownItemPublic = ({ id, title, avatar, addPublic, selected, onChange }) => {
    const dispatch = useDispatch();
    const filteredPublics = useSelector(state => state.author.filteredPublics);
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const currentRequestId = useRef(0);

    const clickNewMarathonHandler = () => {
        if (filteredPublics.length > 0) {
            dispatch(push('/wizard-create-step-1'));
        } else {
            dispatch(push('/wizard-create-public'));
        }
        dispatch(setDropdownOpened(false));
        if (onChange !== undefined) {
            onChange();
        }
    };

    const cropString = stringToCrop => {
        if (stringToCrop.length > 30) {
            return `${stringToCrop.slice(0, 30)}...`;
        }
        return stringToCrop;
    };

    const clickSelectMarathonHandler = () => {
        if (id !== lastSelectedPublic) {
            dispatch(marathonsResetTabPageAndCourses());
            if (process.env.NODE_ENV === 'development') {
                dispatch(setOneStorage('lastSelectedPublic', id.toString()));
            } else {
                dispatch(setStorageKey('lastSelectedPublic', id.toString()));
            }
        }
        dispatch(getMarathons(currentRequestId, id, 1, 1));
        dispatch(setDropdownOpened(false));
    };

    return (
        <>
            {addPublic ? (
                <div className="dropdown--item dropdown--public dropdown--item-last" onClick={clickNewMarathonHandler}>
                    <Icon16Add fill="5181b8" /> <span>{title}</span>
                </div>
            ) : (
                <div
                    className={`dropdown--item dropdown--public${selected ? ' selected' : ''}`}
                    onClick={clickSelectMarathonHandler}
                >
                    <div className="dropdown--avatar" style={{ backgroundImage: `url(${avatar})` }} />
                    <span>{cropString(title)}</span>
                </div>
            )}
        </>
    );
};

DropdownItemPublic.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    avatar: PropTypes.string,
    addPublic: PropTypes.bool,
    selected: PropTypes.bool,
    onChange: PropTypes.func,
};

export default DropdownItemPublic;
