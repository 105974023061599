import * as types from './actionTypes';

export const testSetQuestions = questions => ({
    type: types.TEST_SET_QUESTIONS,
    questions,
});

export const testAddNewQuestion = () => ({
    type: types.TEST_ADD_NEW_QUESTION,
});

export const testRemoveQuestion = qNum => ({
    type: types.TEST_REMOVE_QUESTION,
    qNum,
});

export const testSetCurrentStep = currentStep => ({
    type: types.TEST_SET_CURRENT_STEP,
    currentStep,
});

export const testSetQuestionTitle = (qNum, title) => ({
    type: types.TEST_SET_QUESTION_TITLE,
    qNum,
    title,
});

export const testSetQuestionType = (qNum, qType) => ({
    type: types.TEST_SET_QUESTION_TYPE,
    qNum,
    qType,
});

export const testSetQuestionId = (qNum, qId) => ({
    type: types.TEST_SET_QUESTION_ID,
    qNum,
    qId,
});

export const testSetTextAnswer = (qNum, answerText) => ({
    type: types.TEST_SET_TEXT_ANSWER,
    qNum,
    answerText,
});

export const testSetSelectAnswerText = (qNum, aNum, text) => ({
    type: types.TEST_SET_SELECT_ANSWER_TEXT,
    qNum,
    aNum,
    text,
});

export const testSetSelectAnswerIsRight = (qNum, aNum, isAnswer) => ({
    type: types.TEST_SET_SELECT_ANSWER_IS_RIGHT,
    qNum,
    aNum,
    isAnswer,
});

export const testSetMatchAnswerRight = (qNum, aNum, text) => ({
    type: types.TEST_SET_MATCH_ANSWER_RIGHT,
    qNum,
    aNum,
    text,
});

export const testSetMatchAnswerLeft = (qNum, aNum, text) => ({
    type: types.TEST_SET_MATCH_ANSWER_LEFT,
    qNum,
    aNum,
    text,
});

export const testDeleteSelectAnswer = (qNum, aNum) => ({
    type: types.TEST_DELETE_SELECT_ANSWER,
    qNum,
    aNum,
});

export const testDeleteMatchAnswer = (qNum, aNum) => ({
    type: types.TEST_DELETE_MATCH_ANSWER,
    qNum,
    aNum,
});

export const testReset = () => ({
    type: types.TEST_RESET,
});

export const testAddNewSelectAnswer = qNum => ({
    type: types.TEST_ADD_NEW_SELECT_ANSWER,
    qNum,
});

export const testAddNewMatchAnswer = qNum => ({
    type: types.TEST_ADD_NEW_MATCH_ANSWER,
    qNum,
});

export const testSetQuestion = (question, qNum) => ({
    type: types.TEST_SET_QUESTION,
    question,
    qNum,
});
