import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown';
import RadioIconItem from './RadioIconItem';
import { authorSetIsShowMoreOpenedStatus } from '../store/author/actions';
import { resizeFrame } from '../services/vk';

const WizardItemList = () => {
    const dispatch = useDispatch();
    const filteredPublics = useSelector(state => state.author.filteredPublics);
    const isShowMoreOpened = useSelector(state => state.author.isShowMoreOpened);

    const showAll = () => {
        dispatch(authorSetIsShowMoreOpenedStatus(true));
        resizeFrame();
    };

    const renderList = () => {
        let slicedArray;
        if (!isShowMoreOpened) {
            slicedArray = filteredPublics.slice(0, 5);
        } else {
            slicedArray = filteredPublics;
        }

        return slicedArray.map(fp => (
            <RadioIconItem key={`grp${fp.id}`} icon={fp.photo_200} title={fp.name} id={fp.id} />
        ));
    };

    const showMoreButton = (
        <Button mode="tertiary" after={<Icon16Dropdown fill="9eb4c8" width={12} height={12} />} onClick={showAll}>
            Показать ещё
        </Button>
    );

    return (
        <div className="wizard--list">
            {renderList()}
            {!isShowMoreOpened && filteredPublics.length > 5 && showMoreButton}
        </div>
    );
};

export default WizardItemList;
