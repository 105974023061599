import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { COURSES_PER_PAGE } from '../services/_globals';
import { marathonsSetPaginationPage } from '../store/marathons/actions';
import { getMarathons } from '../services/_functions';
import '../../styles/pagination.scss';

const Pagination = ({ isTop }) => {
    const dispatch = useDispatch();
    const tabIndex = useSelector(state => state.marathons.tabIndex);
    const total = useSelector(state => state.marathons.courses[tabIndex].total);
    const pageNum = useSelector(state => state.marathons.pageNum[tabIndex]);
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const currentRequestId = useRef(0);

    const selectPage = i => {
        dispatch(marathonsSetPaginationPage(tabIndex, i));
        dispatch(getMarathons(currentRequestId, lastSelectedPublic, tabIndex, i));
    };

    const renderPages = () => {
        const pages = [];

        // Получаем номера промежуточных страниц
        const amount = 5;
        const arrOfPages = [];
        for (let i = pageNum - Math.floor(amount / 2); i <= pageNum + Math.floor(amount / 2); i++) {
            arrOfPages.push(i);
        }

        // Добавляем страницы в массив
        for (let i = 1; i <= Math.ceil(total / COURSES_PER_PAGE); i++) {
            const pagKey = isTop ? `pag-top-${i}` : `pag-bot-${i}`;
            if (arrOfPages.includes(i)) {
                pages.push(
                    <span className={pageNum === i ? 'active' : ''} onClick={() => selectPage(i)} key={pagKey}>
                        {i}
                    </span>,
                );
            }

            // Стрелка влево
            if (i === 1 && !arrOfPages.includes(i)) {
                pages.push(
                    <span onClick={() => selectPage(i)} key={isTop ? `pag-top-left` : `pag-bot-left`}>
                        «
                    </span>,
                );
            }

            // Стрелка вправо
            if (i === Math.ceil(total / COURSES_PER_PAGE) && !arrOfPages.includes(i)) {
                pages.push(
                    <span onClick={() => selectPage(i)} key={isTop ? `pag-top-right` : `pag-bot-right`}>
                        »
                    </span>,
                );
            }
        }

        return pages;
    };

    const renderTopPagination = () => (
        <div className={`pagination ${isTop ? 'pagination--profile' : ''}`} key="top-pag-owner">
            {renderPages()}
        </div>
    );

    const renderBottomPagination = () => (
        <div className="block-head--footer" key="bot-pag-owner">
            <div className={`pagination ${isTop ? 'pagination--profile' : ''}`}>{renderPages()}</div>
        </div>
    );

    return [
        isTop && renderPages().length > 0 && renderTopPagination(),
        !isTop && renderPages().length > 0 && renderBottomPagination(),
    ];
};

Pagination.propTypes = {
    isTop: PropTypes.bool,
};

export default Pagination;
