import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import Block from '../../components/Block';
import MarathonTestQuestion from '../../components/MarathonQuestion';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import ModalDel from '../../components/ModalDel';
import { getQuestionsMock } from '../../services/mock';
import { deleteTest, getStepQuestions, goToTop, resizeFrame } from '../../services/vk';
import { testAddNewQuestion, testSetQuestions } from '../../store/test/actions';
import { openModal } from '../../store/globals/actions';
import '../../../styles/block-test.scss';

const PanelMarathonCreateTest = () => {
    const dispatch = useDispatch();
    const marathonTitle = useSelector(state => state.create.marathonTitle);
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const selectedCourseId = useSelector(state => state.create.selectedCourseId);
    const currentStep = useSelector(state => state.test.currentStep);
    const questions = useSelector(state => state.test.questions);
    const marathonStatus = useSelector(state => state.create.marathonStatus);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            (async () => {
                const getQuestionsResult = await getStepQuestions(lastSelectedPublic, selectedCourseId, currentStep);
                if (getQuestionsResult.result === 'success') {
                    dispatch(testSetQuestions(getQuestionsResult.data.items));
                    resizeFrame();
                }
            })();
        } else {
            dispatch(testSetQuestions(getQuestionsMock().items));
        }
        resizeFrame();
    }, []);

    const addQuestion = async () => {
        if (questions[questions.length - 1].question_id !== 0) {
            dispatch(testAddNewQuestion());
            resizeFrame();
        }
    };

    const finishCreate = () => {
        goToTop();
        dispatch(push('/marathon-about'));
    };

    const deleteTestOpenModal = () => {
        dispatch(openModal(<ModalDel title="тест" deleteFunc={deleteTestFunc} bottom={300} />));
    };

    const deleteTestFunc = async () => {
        setIsDeleting(true);
        const deleteResult = await deleteTest(lastSelectedPublic, selectedCourseId, currentStep);
        if (deleteResult.result === 'success') {
            dispatch(push('/marathon-about'));
        }
        setIsDeleting(false);
    };

    const renderQuestions = () => {
        const renderedQuestions = [];
        questions.forEach((q, i) => {
            let a, ad, ae;
            switch (q.type) {
                case 'select':
                    a = q.options;
                    ad = q.optionsDelete;
                    ae = q.optionsEdit;
                    break;
                case 'text':
                    a = q.answer_text;
                    break;
                case 'match':
                    a = q.pairs;
                    ad = q.pairsDelete;
                    ae = q.pairsEdit;
                    break;
            }

            renderedQuestions.push(
                <MarathonTestQuestion
                    key={`qqq${i}`}
                    qNum={i}
                    id={q.question_id}
                    type={q.type}
                    title={q.text}
                    answers={a}
                    answersDelete={ad}
                    answersEdit={ae}
                />,
            );
        });

        return renderedQuestions;
    };

    return (
        <Block
            breadcrumbs={
                <>
                    <BreadcrumbsItem title="VK Edu" link="marathons" />
                    <BreadcrumbsItem title={marathonTitle} link="marathon-about" />
                    <BreadcrumbsItem title="Тест" link="test-create" />
                </>
            }
        >
            {renderQuestions()}
            {marathonStatus === 'draft' && questions.length < 42 && (
                <div className="test--add-question">
                    <div className="test--block">
                        <Button
                            mode="tertiary"
                            before={<Icon24Add fill="5181b8" width={20} height={20} />}
                            onClick={addQuestion}
                        >
                            Добавить вопрос
                        </Button>
                    </div>
                </div>
            )}
            <div className="test--footer">
                {marathonStatus === 'draft' &&
                    ((questions.length > 0 && questions[questions.length - 1].question_id !== 0) ||
                        (questions.length > 1 && questions[questions.length - 2].question_id !== 0)) && (
                        <ButtonWithLoader mode="secondary" isLoading={isDeleting} onClickFunc={deleteTestOpenModal}>
                            Удалить тест
                        </ButtonWithLoader>
                    )}
                <Button onClick={finishCreate}>Завершить создание теста</Button>
            </div>
        </Block>
    );
};

export default PanelMarathonCreateTest;
