import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import Block from '../../components/Block';
import WizardItemList from '../../components/WizardItemList';
import { goToTop, resizeFrame } from '../../services/vk';
import '../../../styles/wizard.scss';

const WizardCreateStep1 = () => {
    const dispatch = useDispatch();
    const selectedPublicId = useSelector(state => state.author.selectedPublicId);
    const marathonedFilledPublics = useSelector(state => state.author.marathonedFilledPublics);

    useEffect(() => {
        resizeFrame();
    }, []);

    const openWizardCreateStep2 = () => {
        dispatch(push('/wizard-create-step-2'));
        goToTop();
    };

    const backToHome = () => {
        dispatch(push('/marathons'));
        goToTop();
    };

    return (
        <Block
            breadcrumbs={
                <>
                    <BreadcrumbsItem large title="VK Edu" link="" />
                </>
            }
        >
            {marathonedFilledPublics.length > 0 && (
                <div className="wizard--back" onClick={backToHome}>
                    <div className="wizard--back-content">
                        <Icon24BrowserBack width={22} height={22} fill="cccccc" />
                        <span>Вернуться назад</span>
                    </div>
                </div>
            )}
            <div className="wizard">
                <div className="wizard--image" />
                <div className="wizard--info">
                    <div className="wizard--title">Выберите сообщество</div>
                    <div className="wizard--subtitle">
                        Марафоны можно публиковать только от имени сообществ. Выберите сообщество, от которого вы будете
                        публиковать марафоны.
                    </div>
                </div>
            </div>
            <div className="wizard--choose">
                <WizardItemList />
                <Button onClick={openWizardCreateStep2} disabled={selectedPublicId === 0}>
                    Сохранить
                </Button>
            </div>
        </Block>
    );
};

export default WizardCreateStep1;
