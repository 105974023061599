export const publicsArrMock = () => [
    {
        description: '',
        id: 187709869,
        is_closed: 1,
        name: 'mosru 1000,111111111,2222222222,slfsklfkl',
        photo_50: 'https://vk.com/images/community_50.png?ava=1',
        photo_100: 'https://vk.com/images/community_100.png?ava=1',
        photo_200: 'https://vk.com/images/community_200.png?ava=1',
        members_count: 9999,
        screen_name: 'club187709869',
        type: 'group',
    },
    {
        description: '',
        id: 187709868,
        is_closed: 1,
        name: 'mosru 2',
        photo_50: 'https://vk.com/images/community_50.png?ava=1',
        photo_100: 'https://vk.com/images/community_100.png?ava=1',
        photo_200: 'https://vk.com/images/community_200.png?ava=1',
        members_count: 999999999,
        screen_name: 'club187709869',
        type: 'page',
    },
    {
        description: '',
        id: 187709867,
        is_closed: 1,
        name: 'mosru 3',
        photo_50: 'https://vk.com/images/community_50.png?ava=1',
        photo_100: 'https://vk.com/images/community_100.png?ava=1',
        photo_200: 'https://vk.com/images/community_200.png?ava=1',
        members_count: 999999999,
        screen_name: 'club187709869',
        type: 'page',
    },
    {
        description: '',
        id: 187709866,
        is_closed: 1,
        name: 'mosru 4',
        photo_50: 'https://vk.com/images/community_50.png?ava=1',
        photo_100: 'https://vk.com/images/community_100.png?ava=1',
        photo_200: 'https://vk.com/images/community_200.png?ava=1',
        members_count: 999999999,
        screen_name: 'club187709869',
        type: 'page',
    },
    {
        description: '',
        id: 187709865,
        is_closed: 1,
        name: 'mosru 5',
        photo_50: 'https://vk.com/images/community_50.png?ava=1',
        photo_100: 'https://vk.com/images/community_100.png?ava=1',
        photo_200: 'https://vk.com/images/community_200.png?ava=1',
        members_count: 999999999,
        screen_name: 'club187709869',
        type: 'page',
    },
    {
        description: '',
        id: 187709864,
        is_closed: 1,
        name: 'mosru 6',
        photo_50: 'https://vk.com/images/community_50.png?ava=1',
        photo_100: 'https://vk.com/images/community_100.png?ava=1',
        photo_200: 'https://vk.com/images/community_200.png?ava=1',
        members_count: 999999999,
        screen_name: 'club187709869',
        type: 'page',
    },
    {
        description: '',
        id: 1877098633,
        is_closed: 1,
        name: 'mosru 7',
        photo_50: 'https://vk.com/images/community_50.png?ava=1',
        photo_100: 'https://vk.com/images/community_100.png?ava=1',
        photo_200: 'https://vk.com/images/community_200.png?ava=1',
        members_count: 999999999,
        screen_name: 'club187709869',
        type: 'page',
    },
];

export const marathonedPublicsArrMock = () => ({
    count: 0,
    items: [
        {
            courses_count: 0,
            id: -187709869,
            rating: 0,
            type: 'school',
        },
        {
            courses_count: 0,
            id: -187709868,
            rating: 0,
            type: 'school',
        },
        {
            courses_count: 0,
            id: -187709867,
            rating: 0,
            type: 'school',
        },
        {
            courses_count: 0,
            id: -187709866,
            rating: 0,
            type: 'school',
        },
        {
            courses_count: 0,
            id: -187709865,
            rating: 0,
            type: 'school',
        },
        {
            courses_count: 0,
            id: -187709864,
            rating: 0,
            type: 'school',
        },
    ],
});

export const vkUserMock = () => ({
    city: {
        id: 26,
        title: 'Белгород',
    },
    country: {
        id: 1,
        title: 'Россия',
    },
    first_name: 'Денис',
    id: 4267535,
    last_name: 'Кот',
    photo_100: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
    photo_200: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
    photo_max_orig: 'https://sun1-30.userapi.com/c850236/v850236245/1d005d/9hRBYDN2ZSc.jpg?ava=1',
    sex: 2,
    timezone: 3,
});

export const authorCoursesArrMock = () => ({
    total: 20,
    items: [
        {
            title: 'course number 1',
            author_id: -123,
            course_id: 1,
            rating: 0,
            step_count: 10,
            type: 'draft',
            categories: [
                {
                    id: 1,
                    tag: 'lifestyle',
                    name: 'lifestyle',
                },
            ],
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
            video_item_id: 456239023,
            video_url: 'https://vk.com/video_ext.php?oid=-135958175&id=456239023&hash=3b358dd453fc1632',
        },
        {
            title: 'course number 2',
            author_id: -123,
            course_id: 7,
            rating: 0,
            step_count: 10,
            type: 'published',
            categories: [
                {
                    id: 1,
                    tag: 'lifestyle',
                    name: 'lifestyle',
                },
            ],
            description: 'desc course 2',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
            video_id: 123,
            video_hash: '32abc324',
            video_url: 'https://vk.com/video_ext.php?oid=-135958175&id=456239023&hash=3b358dd453fc1632',
        },
        {
            title: 'course number 3',
            author_id: -123,
            course_id: 2,
            rating: 0,
            step_count: 10,
            type: 'published',
            categories: [
                {
                    id: 1,
                    tag: 'lifestyle',
                    name: 'lifestyle',
                },
            ],
            description: 'desc course 3',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
            video_id: 123,
            video_hash: '32abc324',
            video_url: 'https://vk.com/video_ext.php?oid=-135958175&id=456239023&hash=3b358dd453fc1632',
        },
        {
            title: 'course number 4',
            author_id: -123,
            course_id: 3,
            rating: 0,
            step_count: 10,
            type: 'published',
            categories: [
                {
                    id: 1,
                    tag: 'lifestyle',
                    name: 'lifestyle',
                },
            ],
            description: 'desc course 4',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
            video_id: 123,
            video_hash: '32abc324',
            video_url: 'https://vk.com/video_ext.php?oid=-135958175&id=456239023&hash=3b358dd453fc1632',
        },
        {
            title: 'course number 5',
            author_id: -123,
            course_id: 4,
            rating: 0,
            step_count: 10,
            type: 'published',
            categories: [
                {
                    id: 1,
                    tag: 'lifestyle',
                    name: 'lifestyle',
                },
            ],
            description: 'desc course 5',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
            video_id: 123,
            video_hash: '32abc324',
            video_url: 'https://vk.com/video_ext.php?oid=-135958175&id=456239023&hash=3b358dd453fc1632',
        },
        {
            title: 'course number 6',
            author_id: -123,
            course_id: 5,
            rating: 0,
            step_count: 10,
            type: 'published',
            categories: [
                {
                    id: 1,
                    tag: 'lifestyle',
                    name: 'lifestyle',
                },
            ],
            description: 'desc course 6',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
            video_id: 123,
            video_hash: '32abc324',
            video_url: 'https://vk.com/video_ext.php?oid=-135958175&id=456239023&hash=3b358dd453fc1632',
        },
        {
            title: 'course number 7',
            author_id: -123,
            course_id: 6,
            rating: 0,
            step_count: 10,
            type: 'published',
            categories: [
                {
                    id: 1,
                    tag: 'lifestyle',
                    name: 'lifestyle',
                },
            ],
            description: 'desc course 7',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
            video_id: 123,
            video_hash: '32abc324',
            video_url: 'https://vk.com/video_ext.php?oid=-135958175&id=456239023&hash=3b358dd453fc1632',
        },
    ],
});

export const authorCoursesDraftArrMock = () => ({
    total: 32,
    items: [
        {
            title: 'course number 1',
            author_id: -123,
            course_id: 1,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'skill \n\r skill2 \n\r course 1',
            is_favorite: true,
            friend_ids: [1],
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 2',
            author_id: -123,
            course_id: 2,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 3',
            author_id: -123,
            course_id: 3,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 4',
            author_id: -123,
            course_id: 4,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 5',
            author_id: -123,
            course_id: 5,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 6',
            author_id: -123,
            course_id: 6,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 7',
            author_id: -123,
            course_id: 7,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 8',
            author_id: -123,
            course_id: 8,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 9',
            author_id: -123,
            course_id: 9,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 10',
            author_id: -123,
            course_id: 10,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 11',
            author_id: -123,
            course_id: 11,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 12',
            author_id: -123,
            course_id: 12,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
    ],
});

export const authorCoursesArchivedArrMock = () => ({
    total: 32,
    items: [
        {
            title: 'course number 1',
            author_id: -123,
            course_id: 1,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            description: 'skill \n\r skill2 \n\r course 1',
            is_favorite: true,
            friend_ids: [1],
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
            video_item_id: 456239023,
            video_url: 'https://vk.com/video_ext.php?oid=-135958175&id=456239023&hash=3b358dd453fc1632',
        },
        {
            title: 'course number 2',
            author_id: -123,
            course_id: 2,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 3',
            author_id: -123,
            course_id: 3,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 4',
            author_id: -123,
            course_id: 4,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 5',
            author_id: -123,
            course_id: 5,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 6',
            author_id: -123,
            course_id: 6,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 7',
            author_id: -123,
            course_id: 7,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 8',
            author_id: -123,
            course_id: 8,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 9',
            author_id: -123,
            course_id: 9,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 10',
            author_id: -123,
            course_id: 10,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 11',
            author_id: -123,
            course_id: 11,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
        {
            title: 'course number 12',
            author_id: -123,
            course_id: 12,
            rating: 0,
            step_count: 10,
            type: 'published',
            tutor_ids: [1, 2, 3],
            categories: {
                id: 1,
                tag: 'lifestyle',
                name: 'lifestyle',
            },
            description: 'desc course 1',
            is_favorite: true,
            friend_ids: [1],
            avatar_photo: [
                {
                    b: {
                        height: 100,
                        url: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
                        width: 100,
                    },
                },
            ],
            cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
            is_active_course: true,
            is_complete_course: false,
            start_course_time: 12334123,
            end_course_time: 12334123,
            students_count: 123,
        },
    ],
});

export const authorInterestsArrMock = () => [
    {
        title: 'course number 1',
        author_id: -123,
        course_id: 1,
        rating: 4,
        step_count: 10,
        type: 'published',
        tutor_ids: [1, 2, 3],
        categories: {
            id: 1,
            tag: 'lifestyle',
            name: 'lifestyle',
        },
        description: 'desc course 1',
        is_favorite: true,
        friend_ids: [1],
        avatar_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        is_active_course: true,
        is_complete_course: false,
        start_course_time: 12334123,
        end_course_time: 12334123,
        students_count: 123,
    },
    {
        title: 'course number 1',
        author_id: -123,
        course_id: 1,
        rating: 4,
        step_count: 10,
        type: 'published',
        tutor_ids: [1, 2, 3],
        categories: {
            id: 1,
            tag: 'lifestyle',
            name: 'lifestyle',
        },
        description: 'desc course 1',
        is_favorite: true,
        friend_ids: [1],
        avatar_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        is_active_course: true,
        is_complete_course: false,
        start_course_time: 12334123,
        end_course_time: 12334123,
        students_count: 123,
    },
    {
        title: 'course number 1',
        author_id: -123,
        course_id: 1,
        rating: 4,
        step_count: 10,
        type: 'published',
        tutor_ids: [1, 2, 3],
        categories: {
            id: 1,
            tag: 'lifestyle',
            name: 'lifestyle',
        },
        description: 'desc course 1',
        is_favorite: true,
        friend_ids: [1],
        avatar_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        is_active_course: true,
        is_complete_course: false,
        start_course_time: 12334123,
        end_course_time: 12334123,
        students_count: 123,
    },
    {
        title: 'course number 1',
        author_id: -123,
        course_id: 1,
        rating: 4,
        step_count: 10,
        type: 'published',
        tutor_ids: [1, 2, 3],
        categories: {
            id: 1,
            tag: 'lifestyle',
            name: 'lifestyle',
        },
        description: 'desc course 1',
        is_favorite: true,
        friend_ids: [1],
        avatar_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        is_active_course: true,
        is_complete_course: false,
        start_course_time: 12334123,
        end_course_time: 12334123,
        students_count: 123,
    },
    {
        title: 'course number 1',
        author_id: -123,
        course_id: 1,
        rating: 4,
        step_count: 10,
        type: 'published',
        tutor_ids: [1, 2, 3],
        categories: {
            id: 1,
            tag: 'lifestyle',
            name: 'lifestyle',
        },
        description: 'desc course 1',
        is_favorite: true,
        friend_ids: [1],
        avatar_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        is_active_course: true,
        is_complete_course: false,
        start_course_time: 12334123,
        end_course_time: 12334123,
        students_count: 123,
    },
    {
        title: 'course number 1',
        author_id: -123,
        course_id: 1,
        rating: 4,
        step_count: 10,
        type: 'published',
        tutor_ids: [1, 2, 3],
        categories: {
            id: 1,
            tag: 'lifestyle',
            name: 'lifestyle',
        },
        description: 'desc course 1',
        is_favorite: true,
        friend_ids: [1],
        avatar_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        cover_photo: 'https://sun1-19.userapi.com/c850236/v850236245/1d0060/FDSpLJlaVBo.jpg?ava=1',
        is_active_course: true,
        is_complete_course: false,
        start_course_time: 12334123,
        end_course_time: 12334123,
        students_count: 123,
    },
];

export const storageMock = () => ({
    keys: [
        {
            key: 'isAddedLeft',
            value: 'false',
        },
        {
            key: 'lastSelectedPublic',
            value: '-187709869',
        },
    ],
});

export const authorTypesMock = () => ({
    types: [
        { value: 'independent_author', translate: 'Независимый автор' },
        { value: 'school', translate: 'Школа' },
        { value: 'high_education', translate: 'Высшее учебное заведение' },
        { value: 'additional_education', translate: 'Дополнительное образование' },
    ],
});

export const createAuthorMock = () => ({
    data: 1,
});

export const saveImageMock = () => ({
    id: 457239400,
    images: {
        a: {
            height: 56,
            url: 'https://sun9-60.userapi.com/fYgrkPjK2ydngRqVXE5CKaZgcYxcYwklZqD8XA/YSWPKfpuUxA.jpg',
            width: 56,
        },
        b: {
            height: 112,
            url: 'https://sun9-61.userapi.com/S3QG-eNijyP4JljuL_-yIjY-_ABtE5fKKKycWw/3PBRgD3GFLg.jpg',
            width: 112,
        },
        c: {
            height: 224,
            url: 'https://sun9-35.userapi.com/2XJlvflrgThKdhj6WYi9gFvgh0liAHnK3Py9oA/YuYkt1XkF1Q.jpg',
            width: 224,
        },
        d: {
            height: 448,
            url: 'https://sun9-53.userapi.com/YCD-9xTI5xRHCfs_GRKk6MW6xlZq2s1JiJv11g/HWp_nNqvM7A.jpg',
            width: 448,
        },
        e: {
            height: 500,
            url: 'https://sun9-67.userapi.com/BtkawBma-Sb9GDTHWFZeh0syapgO67knl_vujQ/QXnlnk8bGK8.jpg',
            width: 500,
        },
    },
});

export const categoriesArrMock = () => ({
    items: [
        { id: 1, tag: 'lifestyle', name: '' },
        { id: 2, tag: 'health_and_fitness', name: '' },
        { id: 3, tag: 'languages', name: '' },
        { id: 4, tag: 'marketing', name: '' },
        { id: 5, tag: 'business', name: '' },
        { id: 6, tag: 'personal_growth', name: '' },
        { id: 7, tag: 'design', name: '' },
        { id: 8, tag: 'photo', name: '' },
        { id: 9, tag: 'it_and_development', name: '' },
        { id: 10, tag: 'finance_and_accounting', name: '' },
        { id: 11, tag: 'music', name: '' },
        { id: 12, tag: 'training', name: '' },
        { id: 13, tag: 'boring_science', name: '' },
        { id: 14, tag: 'academic_disciplines', name: '' },
        { id: 15, tag: 'other', name: '' },
    ],
});

export const videoUrlMock = () => ({
    response: {
        url: 'https://vk.com/video_ext.php?oid=-135958175&id=456239026&hash=144a88f9b98d69b4',
    },
});

export const createCourseMock = () => ({
    course_id: 7,
    author_id: -135958175,
});

export const getStepsMock = () => [
    {
        article_id: 40630,
        article_status: 'draft',
        article_url: '/@-135958175-vk-education-course-8-glava-1',
        author_id: -135958175,
        course_id: 8,
        has_test: true,
        step_id: 1,
        title: 'Обучение React.js — Глава 1',
    },
    {
        article_id: 40630,
        article_status: 'draft',
        article_url: '/@-135958175-vk-education-course-8-glava-1',
        author_id: -135958175,
        course_id: 8,
        has_test: false,
        step_id: 2,
        title: 'Обучение React.js — Глава 2',
    },
    {
        article_id: 40630,
        article_status: 'draft',
        article_url: '/@-135958175-vk-education-course-8-glava-1',
        author_id: -135958175,
        course_id: 8,
        has_test: false,
        step_id: 3,
        title: 'Обучение React.js — Глава 3',
    },
    {
        article_id: 40630,
        article_status: 'draft',
        article_url: '/@-135958175-vk-education-course-8-glava-1',
        author_id: -135958175,
        course_id: 8,
        has_test: false,
        step_id: 4,
        title: 'Обучение React.js — Глава 4',
    },
    {
        article_id: 40630,
        article_status: 'draft',
        article_url: '/@-135958175-vk-education-course-8-glava-1',
        author_id: -135958175,
        course_id: 8,
        has_test: false,
        step_id: 5,
        title: 'Обучение React.js — Глава 5',
    },
];

export const getQuestionsMock = () => ({
    count: 0,
    items: [
        { answer_text: 'Ответик', question_id: 1, text: 'Вопрос с текстом', type: 'text' },
        {
            answer_ids: [131, 133],
            options: [
                { id: 131, text: 'Вариант А' },
                { id: 132, text: 'Вариант B' },
                { id: 133, text: 'Вариант C' },
            ],
            question_id: 2,
            text: 'Вопрос с выбором',
            type: 'select',
        },
        {
            pairs: [
                {
                    pair: [
                        { id: 141, text: '1', pid: 140 },
                        { id: 140, text: 'One', pid: 141 },
                    ],
                },
                {
                    pair: [
                        { id: 139, text: '2', pid: 134 },
                        { id: 134, text: 'Two', pid: 139 },
                    ],
                },
                {
                    pair: [
                        { id: 138, text: '3', pid: 136 },
                        { id: 136, text: 'Three', pid: 138 },
                    ],
                },
                {
                    pair: [
                        { id: 135, text: '4', pid: 137 },
                        { id: 137, text: 'Four', pid: 135 },
                    ],
                },
            ],
            question_id: 3,
            text: 'Вопрос с парами',
            type: 'match',
        },
    ],
    offset: 0,
    total: 0,
});
