import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { testSetTextAnswer } from '../store/test/actions';

const MarathonAnswerText = ({ qNum, text }) => {
    const dispatch = useDispatch();

    const updateAnswer = e => {
        dispatch(testSetTextAnswer(qNum, e.target.value));
    };

    return <input placeholder="Введите ответ" value={text} onChange={updateAnswer} maxLength={32} />;
};

MarathonAnswerText.propTypes = {
    text: PropTypes.string,
    qNum: PropTypes.number,
};

export default MarathonAnswerText;
