import { marathonsSetCourses, marathonsSetStopLoading } from '../store/marathons/actions';
import { authorCoursesArrMock } from './mock';
import { getAuthorCourses, resizeFrame } from './vk';

// Падеж
export const wordPad = (num, t, ta, tov) => {
    num %= 100;
    if (num % 10 === 1 && (num < 10 || num > 20)) {
        return t;
    }
    if (num % 10 >= 2 && num % 10 <= 4 && (num < 10 || num > 20)) {
        return ta;
    }
    return tov;
};

// Нули для больших чисел
export const numberWithSpaces = x => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
};

// Получаем ID приложения
export const getAppId = () => {
    return +new URL(window.location.href).searchParams.get('vk_app_id');
};

// Получаем ID приложения
export const getCurrentDateTime = () => {
    const now = new Date();
    const day = now.getDate();
    let month = (now.getMonth() + 1).toString();
    if (month.length === 1) {
        month = `0${month}`;
    }
    const year = now
        .getFullYear()
        .toString()
        .substr(2, 2);
    const hours = now.getHours();
    let minutes = now.getMinutes().toString();
    if (minutes.length === 1) {
        minutes = `0${minutes}`;
    }
    return `${day}.${month}.${year} в ${hours}:${minutes}`;
};

// Создаем фейк файл
export const makeFakeFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

// Скопировать массив
export const copyArray = array => JSON.parse(JSON.stringify(array));

export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const getNewRequestId = () => Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();

export const randomInteger = (min, max) => Math.floor(min + Math.random() * (max + 1 - min));

export const getMarathons = (
    currentRequestId,
    lastSelectedPublic,
    tabIndex,
    pageNum,
    isLoaded = false,
) => async dispatch => {
    if (!isLoaded) {
        currentRequestId.current = +getNewRequestId();

        if (process.env.NODE_ENV === 'development') {
            dispatch(marathonsSetCourses(tabIndex, authorCoursesArrMock().items, authorCoursesArrMock().total));
        } else {
            const authorCourses = await getAuthorCourses(
                lastSelectedPublic,
                tabIndex,
                pageNum,
                currentRequestId.current,
            );

            if (authorCourses.result === 'success' && authorCourses.data.request_id === currentRequestId.current) {
                dispatch(
                    marathonsSetCourses(tabIndex, authorCourses.data.response.items, authorCourses.data.response.total),
                );
            } else {
                dispatch(marathonsSetStopLoading(tabIndex));
            }
            resizeFrame();
        }
    }
};

export const numberWithKM = num => {
    if (num > 999 && num <= 999999) {
        return Math.floor(num / 100) / 10 + 'K';
    } else if (num > 999999) {
        return Math.floor(num / 100000) / 10 + 'M';
    } else {
        return num;
    }
};

// Получаем инфу по выбранному паблику
export const getSelectedPublicInfo = (lastSelectedPublic, marathonedFilledPublics) => {
    for (let i = 0; i < marathonedFilledPublics.length; i++) {
        if (marathonedFilledPublics[i].id === lastSelectedPublic) {
            return marathonedFilledPublics[i].info;
        }
    }
    return {
        description: '',
        id: 0,
        is_closed: 1,
        name: '',
        photo_50: 'https://vk.com/images/community_50.png?ava=1',
        photo_100: 'https://vk.com/images/community_100.png?ava=1',
        photo_200: 'https://vk.com/images/community_200.png?ava=1',
        members_count: 0,
        screen_name: '',
        type: 'page',
    };
};
