import React from 'react';
import PropTypes from 'prop-types';

const DropdownMenuItem = ({ children, clickAction }) => {
    const clickHandler = e => {
        e.target.parentElement.parentElement.classList.add('hide');
        clickAction();
    };

    return (
        <div className="dropdown-menu--item" onClick={clickHandler}>
            {children}
        </div>
    );
};

DropdownMenuItem.propTypes = {
    children: PropTypes.node,
    clickAction: PropTypes.func,
};

export default DropdownMenuItem;
