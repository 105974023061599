import * as types from './actionTypes';

export const createSetMarathonTitle = marathonTitle => ({
    type: types.CREATE_SET_MARATHON_TITLE,
    marathonTitle,
});

export const createSetMarathonDesc = marathonDesc => ({
    type: types.CREATE_SET_MARATHON_DESC,
    marathonDesc,
});

export const createSetCover = cover => ({
    type: types.CREATE_SET_COVER,
    cover,
});

export const createSetAvatar = avatar => ({
    type: types.CREATE_SET_AVATAR,
    avatar,
});

export const createSetCoverId = coverId => ({
    type: types.CREATE_SET_COVER_ID,
    coverId,
});

export const createSetAvatarId = avatarId => ({
    type: types.CREATE_SET_AVATAR_ID,
    avatarId,
});

export const createSetCategories = categories => ({
    type: types.CREATE_SET_CATEGORIES,
    categories,
});

export const createSelectCategory = category => ({
    type: types.CREATE_SELECT_CATEGORY,
    category,
});

export const createSetVideoLink = videoLink => ({
    type: types.CREATE_SET_VIDEO_LINK,
    videoLink,
});

export const createSetVideoOwnerAndId = (videoOwner, videoId) => ({
    type: types.CREATE_SET_VIDEO_OWNER_AND_ID,
    videoOwner,
    videoId,
});

export const createSetVideoPreviewUrl = videoPreviewUrl => ({
    type: types.CREATE_SET_VIDEO_PREVIEW_URL,
    videoPreviewUrl,
});

export const createSetSelectedCourseId = selectedCourseId => ({
    type: types.CREATE_SET_SELECTED_COURSE_ID,
    selectedCourseId,
});

export const createResetCourseInfo = () => ({
    type: types.CREATE_RESET_COURSE_INFO,
});

export const createSetMarathonsAmount = marathonsAmount => ({
    type: types.CREATE_SET_MARATHONS_AMOUNT,
    marathonsAmount,
});

export const createSetCourseSteps = steps => ({
    type: types.CREATE_SET_COURSE_STEPS,
    steps,
});

export const createAddCourseSteps = step => ({
    type: types.CREATE_ADD_COURSE_STEPS,
    step,
});

export const createSetCourseInfo = courseInfo => ({
    type: types.CREATE_SET_COURSE_INFO,
    courseInfo,
});

export const createSetMarathonStatus = marathonStatus => ({
    type: types.CREATE_SET_MARATHON_STATUS,
    marathonStatus,
});

export const createSetIsMarathonJustCreated = isMarathonJustCreated => ({
    type: types.CREATE_SET_IS_MARATHON_JUST_CREATED,
    isMarathonJustCreated,
});

export const createDeleteStep = stepId => ({
    type: types.CREATE_DELETE_STEP,
    stepId,
});

export const createReplaceStep = step => ({
    type: types.CREATE_REPLACE_STEP,
    step,
});

export const createSetIsOpenSettings = isOpenSettings => ({
    type: types.CREATE_SET_IS_OPEN_SETTINGS,
    isOpenSettings,
});

export const createCopySettings = () => ({
    type: types.CREATE_COPY_SETTINGS,
});

export const createRestoreSettings = () => ({
    type: types.CREATE_RESTORE_SETTINGS,
});

export const createSetIsAvatarEdited = isAvatarEdited => ({
    type: types.CREATE_SET_IS_AVATAR_EDITED,
    isAvatarEdited,
});

export const createSetIsCoverEdited = isCoverEdited => ({
    type: types.CREATE_SET_IS_COVER_EDITED,
    isCoverEdited,
});

export const createSetIsVideoEdited = isVideoEdited => ({
    type: types.CREATE_SET_IS_VIDEO_EDITED,
    isVideoEdited,
});

export const createSetIsCopied = isCopied => ({
    type: types.CREATE_SET_IS_COPIED,
    isCopied,
});
