import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import { closeModal } from '../store/globals/actions';
import { getAvatarUploadServer, getCoverUploadServer, saveCourseAvatar, saveCourseCover } from '../services/vk';
import { uploadImageToServer } from '../services/axios';
import {
    createSetAvatar,
    createSetAvatarId,
    createSetCover,
    createSetCoverId,
    createSetIsAvatarEdited,
    createSetIsCoverEdited,
} from '../store/create/actions';
import { saveImageMock } from '../services/mock';
import ButtonWithLoader from './ButtonWithLoader';
import '../../styles/modal.scss';
import '../../styles/modal-load.scss';

const ModalLoadImg = ({ isCover, title, text, sizeText }) => {
    const dispatch = useDispatch();
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const [uploadError, setUploadError] = useState('');
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const fileRef = useRef(null);

    const closeModalOverlay = e => {
        if (!isLoadingImage) {
            if (e.target.classList.contains('modal--overlay')) {
                dispatch(closeModal(''));
            }
        }
    };

    const closeMdl = () => {
        if (!isLoadingImage) {
            dispatch(closeModal(null));
        }
    };

    const hasError = (
        <div className="modal-load--error">
            <b>К сожалению, произошла ошибка</b>
            <span>{uploadError}</span>
        </div>
    );

    const handleChangeFile = e => {
        if (e.target.files && e.target.files[0]) {
            saveImg(e.target.files[0]);
        }
    };

    const saveImg = async image => {
        setIsLoadingImage(true);

        if (process.env.NODE_ENV === 'production') {
            if (isCover) {
                const result = await getCoverUploadServer(lastSelectedPublic);

                if (result.result === 'success') {
                    const uploadUrl = result.data.upload_url;
                    const uploadResult = await uploadImageToServer(uploadUrl, image);

                    if (uploadResult.result === 'success') {
                        const hash = uploadResult.data.data.hash;
                        const picture = uploadResult.data.data.photo;
                        const saveResult = await saveCourseCover(lastSelectedPublic, picture, hash);

                        if (saveResult.result === 'success') {
                            dispatch(createSetIsCoverEdited(true));
                            dispatch(createSetCover(saveResult.data.images.c.url));
                            dispatch(createSetCoverId(saveResult.data.id));
                            closeMdl();
                        } else {
                            setUploadError('Мы не смогли загрузить изображение.');
                        }
                    } else {
                        setUploadError('Мы не смогли загрузить изображение.');
                    }
                } else {
                    setUploadError('Мы не смогли загрузить изображение.');
                }
            } else {
                const result = await getAvatarUploadServer(lastSelectedPublic);

                if (result.result === 'success') {
                    const uploadUrl = result.data.upload_url;
                    const uploadResult = await uploadImageToServer(uploadUrl, image);

                    if (uploadResult.result === 'success') {
                        const hash = uploadResult.data.data.hash;
                        const picture = uploadResult.data.data.photo;
                        const saveResult = await saveCourseAvatar(lastSelectedPublic, picture, hash);

                        if (saveResult.result === 'success') {
                            dispatch(createSetIsAvatarEdited(true));
                            dispatch(createSetAvatar(saveResult.data.images.c.url));
                            dispatch(createSetAvatarId(saveResult.data.id));
                            closeMdl();
                        } else {
                            setUploadError('Мы не смогли загрузить изображение.');
                        }
                    } else {
                        setUploadError('Мы не смогли загрузить изображение.');
                    }
                } else {
                    setUploadError('Мы не смогли загрузить изображение.');
                }
            }
        } else {
            if (isCover) {
                dispatch(createSetCover(saveImageMock().images.c.url));
                dispatch(createSetCoverId(saveImageMock().id));
            } else {
                dispatch(createSetAvatar(saveImageMock().images.c.url));
                dispatch(createSetAvatarId(saveImageMock().id));
            }
            closeMdl();
        }

        setIsLoadingImage(false);
    };

    const openFileUploader = () => {
        fileRef.current.click();
    };

    return (
        <div className="modal--overlay" onClick={e => closeModalOverlay(e)}>
            <div className="modal--content">
                <div className="modal-load">
                    <div className="modal-load--header">
                        <span>{title}</span>
                        <div className="modal--close" onClick={closeMdl}>
                            <Icon24Cancel fill="var(--icon_secondary)" />
                        </div>
                    </div>
                    <div className={`modal-load--body${uploadError !== '' ? ' error' : ''}`}>
                        {uploadError !== '' ? hasError : ''}
                        <div className="modal-load--text">
                            {text}
                            <br />
                            Вы можете загрузить изображение в формате JPG или PNG.
                            <br />
                            Минимальный размер {sizeText} пикселя.
                        </div>
                        <input
                            ref={fileRef}
                            className="hide"
                            type="file"
                            accept="image/jpeg,image/png"
                            onChange={handleChangeFile}
                        />
                        <ButtonWithLoader onClickFunc={openFileUploader} isLoading={isLoadingImage}>
                            Выбрать файл
                        </ButtonWithLoader>
                    </div>
                </div>
            </div>
        </div>
    );
};

ModalLoadImg.propTypes = {
    isCover: PropTypes.bool,
    globalsCloseModal: PropTypes.func,
    title: PropTypes.string,
    text: PropTypes.string,
    sizeText: PropTypes.string,
};

export default ModalLoadImg;
