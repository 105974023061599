import * as types from './actionTypes';

export const openModal = modal => ({
    type: types.OPEN_MODAL,
    modal,
});

export const closeModal = () => ({
    type: types.CLOSE_MODAL,
});

export const setStartInfoLoaded = () => ({
    type: types.SET_START_INFO_LOADED,
});

export const setDropdownOpened = isDropdownOpened => ({
    type: types.SET_DROPDOWN_OPENED,
    isDropdownOpened,
});
