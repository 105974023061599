import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { globals } from './globals/reducers';
import { vk } from './vk/reducers';
import { storage } from './storage/reducers';
import { author } from './author/reducers';
import { create } from './create/reducers';
import { test } from './test/reducers';
import { marathons } from './marathons/reducers';

export const rootReducer = combineReducers({
    router: routerReducer,
    globals,
    vk,
    storage,
    author,
    create,
    test,
    marathons,
});
