import React from 'react';
import PropTypes from 'prop-types';
import Icon24Dropdown from '@vkontakte/icons/dist/24/dropdown';
import '../../styles/dropdown-menu.scss';

const DropdownMenu = ({ title, children }) => {
    const removeHide = e => {
        e.target.classList.remove('hide');
    };

    return (
        <div className="dropdown-menu" onMouseMove={removeHide}>
            <div className="dropdown-menu--label">
                <span>{title}</span> <Icon24Dropdown width={15} height={15} fill="var(--icon_tertiary)" />
            </div>
            <div className="dropdown-menu--items">{children}</div>
        </div>
    );
};

DropdownMenu.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default DropdownMenu;
