import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';
import FormInputDel from './FormInputDel';
import { testSetSelectAnswerIsRight } from '../store/test/actions';

const MarathonAnswerSelect = ({ id, qNum, aNum, text, isRight, isLast, answersTotal }) => {
    const dispatch = useDispatch();
    const marathonStatus = useSelector(state => state.create.marathonStatus);

    const checkClick = () => {
        dispatch(testSetSelectAnswerIsRight(qNum, aNum, !isRight));
    };

    return (
        <div className="test--input">
            <FormInputDel
                id={id}
                qNum={qNum}
                aNum={aNum}
                text={text}
                isLast={isLast}
                placeholder="Добавить вариант"
                answersTotal={answersTotal}
            />
            <Checkbox
                className={'checkbox-custom'}
                disabled={marathonStatus !== 'draft'}
                checked={isRight}
                onChange={checkClick}
            >
                Правильный
            </Checkbox>
        </div>
    );
};

MarathonAnswerSelect.propTypes = {
    id: PropTypes.number,
    qNum: PropTypes.number,
    aNum: PropTypes.number,
    text: PropTypes.string,
    isRight: PropTypes.bool,
    isLast: PropTypes.bool,
    answersTotal: PropTypes.number,
};

export default MarathonAnswerSelect;
