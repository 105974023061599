import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import CustomScroll from 'react-custom-scroll';
import DropdownItemPublic from './DropdownItemPublic';
import Dropdown from './Dropdown';
import { setDropdownOpened } from '../store/globals/actions';
import { getSelectedPublicInfo } from '../services/_functions';
import '../../styles/dropdown.scss';
import '../../styles/custom-scroll.scss';

const AuthorDropdown = onChangeItem => {
    const dispatch = useDispatch();
    const isDropdownOpened = useSelector(state => state.globals.isDropdownOpened);
    const marathonedFilledPublics = useSelector(state => state.author.marathonedFilledPublics);
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (isDropdownOpened) {
                if (wrapperRef && !wrapperRef.current.contains(event.target)) {
                    dispatch(setDropdownOpened(false));
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpened]);

    const toggleDropdown = () => {
        dispatch(setDropdownOpened(!isDropdownOpened));
    };

    const getSelectedAva = () => {
        const result = getSelectedPublicInfo(lastSelectedPublic, marathonedFilledPublics);
        return result.photo_50;
    };

    const renderList = () =>
        marathonedFilledPublics.map(mp => (
            <DropdownItemPublic
                id={mp.id}
                avatar={mp.info !== undefined ? mp.info.photo_50 : ''}
                title={mp.info !== undefined ? mp.info.name : ''}
                selected={lastSelectedPublic === mp.id}
                key={`drpdwn${mp.id}`}
            />
        ));

    return (
        <div ref={wrapperRef} className={`avatar-action${isDropdownOpened ? ' opened' : ''}`}>
            <Dropdown title={<Avatar size={28} src={getSelectedAva()} />} sizeIcon={16} onClickAva={toggleDropdown}>
                <div
                    className={`custom-scroll-dropdown-parent${
                        marathonedFilledPublics.length > 4 ? ' fixed-height' : ''
                    }`}
                >
                    <CustomScroll heightRelativeToParent="100%">{renderList()}</CustomScroll>
                </div>
                <DropdownItemPublic addPublic title="Добавить сообщество" link="#" />
            </Dropdown>
        </div>
    );
};

export default AuthorDropdown;
