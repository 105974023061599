import React from 'react';
import { useSelector } from 'react-redux';
import ProgramItem from './ProgramItem';

const ProgramItemList = () => {
    const steps = useSelector(state => state.create.steps);

    const renderList = () =>
        steps.map(s => (
            <ProgramItem
                key={`pi${s.step_id}`}
                title={s.title}
                rating={s.rating}
                stepId={s.step_id}
                articleId={s.article_id}
                hasTest={s.has_test}
            />
        ));

    return <div className="program--list">{renderList()}</div>;
};

export default ProgramItemList;
