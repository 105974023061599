export const TEST_SET_QUESTIONS = 'TEST_SET_QUESTIONS';
export const TEST_ADD_NEW_QUESTION = 'TEST_ADD_NEW_QUESTION';
export const TEST_REMOVE_QUESTION = 'TEST_REMOVE_QUESTION';
export const TEST_SET_CURRENT_STEP = 'TEST_SET_CURRENT_STEP';
export const TEST_SET_QUESTION_TITLE = 'TEST_SET_QUESTION_TITLE';
export const TEST_SET_QUESTION_TYPE = 'TEST_SET_QUESTION_TYPE';
export const TEST_SET_QUESTION_ID = 'TEST_SET_QUESTION_ID';
export const TEST_SET_TEXT_ANSWER = 'TEST_SET_TEXT_ANSWER';
export const TEST_SET_SELECT_ANSWER_TEXT = 'TEST_SET_SELECT_ANSWER_TEXT';
export const TEST_SET_SELECT_ANSWER_IS_RIGHT = 'TEST_SET_SELECT_ANSWER_IS_RIGHT';
export const TEST_SET_MATCH_ANSWER_LEFT = 'TEST_SET_MATCH_ANSWER_LEFT';
export const TEST_SET_MATCH_ANSWER_RIGHT = 'TEST_SET_MATCH_ANSWER_RIGHT';
export const TEST_DELETE_SELECT_ANSWER = 'TEST_DELETE_SELECT_ANSWER';
export const TEST_DELETE_MATCH_ANSWER = 'TEST_DELETE_MATCH_ANSWER';
export const TEST_RESET = 'TEST_RESET';
export const TEST_ADD_NEW_SELECT_ANSWER = 'TEST_ADD_NEW_SELECT_ANSWER';
export const TEST_ADD_NEW_MATCH_ANSWER = 'TEST_ADD_NEW_MATCH_ANSWER';
export const TEST_SET_QUESTION = 'TEST_SET_QUESTION';
