import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import Radio from '@vkontakte/vkui/dist/components/Radio/Radio';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import Block from '../../components/Block';
import {
    authorAddMarathonedPublic,
    authorSelectAuthorType,
    authorUpdateFilteredPublics,
    authorUpdateMarathonedFilledPublics,
} from '../../store/author/actions';
import { createAuthor, goToTop, resizeFrame, setStorageKey } from '../../services/vk';
import { createAuthorMock } from '../../services/mock';
import { marathonsResetTabPageAndCourses } from '../../store/marathons/actions';
import { setOneStorage } from '../../store/storage/actions';
import { openModal } from '../../store/globals/actions';
import ModalError from '../../components/ModalError';
import '../../../styles/wizard.scss';

const PanelWizardCreateStep2 = () => {
    const dispatch = useDispatch();
    const selectedPublicAva = useSelector(state => state.author.selectedPublicAva);
    const selectedPublicId = useSelector(state => state.author.selectedPublicId);
    const selectedPublicTitle = useSelector(state => state.author.selectedPublicTitle);
    const selectedAuthorType = useSelector(state => state.author.selectedAuthorType);
    const authorTypes = useSelector(state => state.author.authorTypes);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        resizeFrame();
    }, []);

    const openWizardCreateStep1 = () => {
        if (!isSaving) {
            dispatch(push('/wizard-create-step-1'));
            goToTop();
        }
    };

    const saveClickHandler = async () => {
        if (!isSaving) {
            setIsSaving(true);

            let createAuthorResult;
            if (process.env.NODE_ENV === 'development') {
                createAuthorResult = createAuthorMock();
            } else {
                createAuthorResult = await createAuthor(-selectedPublicId, selectedAuthorType);
            }

            // Если сохранение прошло успешно
            if (createAuthorResult.data !== undefined && createAuthorResult.data === 1) {
                // Обновляем паблики
                dispatch(
                    authorAddMarathonedPublic({
                        title: selectedPublicTitle,
                        photo_200: selectedPublicAva,
                        id: -selectedPublicId,
                    }),
                );
                dispatch(authorUpdateFilteredPublics());
                dispatch(authorUpdateMarathonedFilledPublics());

                // Обновляем ключ в storage
                if (process.env.NODE_ENV === 'development') {
                    dispatch(setOneStorage('lastSelectedPublic', (-selectedPublicId).toString()));
                } else {
                    dispatch(setStorageKey('lastSelectedPublic', (-selectedPublicId).toString()));
                }

                // Сбрасываем страницу, таб и курсы
                dispatch(marathonsResetTabPageAndCourses());

                dispatch(push('/marathons'));
                goToTop();
            } else {
                dispatch(openModal(<ModalError title="Произошла ошибка. Попробуйте еще раз." />));
            }

            setIsSaving(false);
        }
    };

    const selectType = e => {
        dispatch(authorSelectAuthorType(e.target.value));
    };

    const renderTypes = () =>
        authorTypes.map(at => (
            <Radio
                key={`slcrdb${at.value}`}
                name="radio"
                value={at.value}
                onClick={selectType}
                defaultChecked={selectedAuthorType === at.value}
            >
                {at.translate}
            </Radio>
        ));

    return (
        <Block
            breadcrumbs={
                <>
                    <BreadcrumbsItem large title="VK Edu" link="" />
                </>
            }
        >
            <div className="wizard--back" onClick={openWizardCreateStep1}>
                <div className="wizard--back-content">
                    <Icon24BrowserBack width={22} height={22} fill="cccccc" />
                    <span>Вернуться назад</span>
                </div>
            </div>
            <div className="wizard">
                <div
                    className="wizard--image wizard--avatar"
                    style={{ backgroundImage: `url(${selectedPublicAva})` }}
                />
                <div className="wizard--info">
                    <div className="wizard--title">Давайте познакомимся!</div>
                    <div className="wizard--subtitle">Расскажите, кого представляет ваше сообщество.</div>
                </div>
            </div>
            <div className="wizard--type">
                <div>
                    <span>Тип автора</span>
                    {renderTypes()}
                </div>
                <ButtonWithLoader
                    onClickFunc={saveClickHandler}
                    disabled={selectedAuthorType === ''}
                    isLoading={isSaving}
                >
                    Сохранить
                </ButtonWithLoader>
            </div>
        </Block>
    );
};

export default PanelWizardCreateStep2;
