import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import AuthorDropdown from '../../components/AuthorDropdown';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import Block from '../../components/Block';
import FormGroup from '../../components/FormGroup';
import FormLoadImg from '../../components/FormLoadImg';
import FormVideo from '../../components/FormVideo';
import FormLabel from '../../components/FormLabel';
import Select from '../../components/Select';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import ModalError from '../../components/ModalError';
import {
    createSelectCategory,
    createSetCategories,
    createSetIsCopied,
    createSetIsMarathonJustCreated,
    createSetMarathonDesc,
    createSetMarathonTitle,
    createSetSelectedCourseId,
    createSetVideoOwnerAndId,
    createSetVideoPreviewUrl,
} from '../../store/create/actions';
import { createCourse, getCategories, getVideoUrl, resizeFrame, updateCourse } from '../../services/vk';
import { getNewRequestId, sleep } from '../../services/_functions';
import { categoriesArrMock, createCourseMock, videoUrlMock } from '../../services/mock';
import { openModal } from '../../store/globals/actions';
import { marathonsUpdateMarathon } from '../../store/marathons/actions';
import '../../../styles/marathon-create.scss';

const PanelMarathonCreate = () => {
    const dispatch = useDispatch();
    const marathonTitle = useSelector(state => state.create.marathonTitle);
    const marathonDesc = useSelector(state => state.create.marathonDesc);
    const videoLink = useSelector(state => state.create.videoLink);
    const videoId = useSelector(state => state.create.videoId);
    const avatar = useSelector(state => state.create.avatar);
    const cover = useSelector(state => state.create.cover);
    const avatarId = useSelector(state => state.create.avatarId);
    const coverId = useSelector(state => state.create.coverId);
    const categories = useSelector(state => state.create.categories);
    const category = useSelector(state => state.create.category);
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const isOpenSettings = useSelector(state => state.create.isOpenSettings);
    const selectedCourseId = useSelector(state => state.create.selectedCourseId);
    const isAvatarEdited = useSelector(state => state.create.isAvatarEdited);
    const isCoverEdited = useSelector(state => state.create.isCoverEdited);
    const isVideoEdited = useSelector(state => state.create.isVideoEdited);
    const marathonStatus = useSelector(state => state.create.marathonStatus);
    const [createTitle, setCreateTitle] = useState(marathonTitle);
    const [selectTitle, setSelectTitle] = useState('Выберите категорию');
    const [videoError, setVideoError] = useState('');
    const [getVideoUrlTimeout, setGetVideoUrlTimeout] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const currentRequestId = useRef(0);
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            (async () => {
                if (categories.length === 0) {
                    await getSelectCategories();
                } else {
                    getCategorySelectTitle(categories);
                }
            })();
        } else {
            dispatch(createSetCategories(categoriesArrMock().items));
            getCategorySelectTitle(categoriesArrMock().items);
        }
        resizeFrame();
    }, []);

    useEffect(() => {
        if (!isFirstRun.current) {
            dispatch(createSetVideoPreviewUrl(''));
            checkVideoLink();
            resizeFrame();
        } else {
            isFirstRun.current = false;
        }
    }, [videoLink, lastSelectedPublic]);

    const prepareCategoriesForSelect = () => {
        const items = [];
        categories.forEach(cat => {
            items.push({ title: cat.name === '' ? cat.tag : cat.name, value: cat.id });
        });
        return items;
    };

    const getSelectCategories = async () => {
        const categoriesGet = await getCategories();
        if (categoriesGet.result === 'success') {
            dispatch(createSetCategories(categoriesGet.data.items));
            getCategorySelectTitle(categoriesGet.data.items);
        } else {
            await sleep(333);
            await getSelectCategories();
        }
    };

    const getCategorySelectTitle = cats => {
        cats.forEach(cat => {
            if (cat.id === category) {
                setSelectTitle(cat.name === '' ? cat.tag : cat.name);
            }
        });
    };

    const setTitle = e => {
        dispatch(createSetMarathonTitle(e.target.value));
    };

    const setDesc = e => {
        dispatch(createSetMarathonDesc(e.target.value));
    };

    const setCategory = val => {
        dispatch(createSelectCategory(val));
    };

    const clearVideoOwnerAndId = () => {
        dispatch(createSetVideoOwnerAndId(0, 0));
    };

    const createNewCourse = async () => {
        setIsCreating(true);
        if (!isCreating) {
            let createResult;
            if (process.env.NODE_ENV === 'production') {
                createResult = await createCourse(
                    lastSelectedPublic,
                    marathonTitle.trim(),
                    marathonDesc.trim(),
                    category,
                    avatarId,
                    coverId,
                    videoId,
                );
            } else {
                createResult = {};
                createResult.result = 'success';
                createResult.data = createCourseMock();
            }

            if (createResult.result === 'success') {
                dispatch(createSetIsMarathonJustCreated(true));
                dispatch(createSetSelectedCourseId(createResult.data.course_id));
                dispatch(createSetMarathonTitle(marathonTitle.trim()));
                dispatch(createSetMarathonDesc(marathonDesc.trim()));
                dispatch(push('/marathon-about'));
            } else {
                if (createResult.e.error_data.error_reason.error_code === 100) {
                    dispatch(openModal(<ModalError title="Одно из полей было заполнено неверно." />));
                } else {
                    dispatch(
                        openModal(
                            <ModalError title="Произошла ошибка, из-за которой не удалось создать марафон. Попробуйте еще раз." />,
                        ),
                    );
                }
            }

            setIsCreating(false);
        }
    };

    const updateThisCourse = async () => {
        setIsCreating(true);
        if (!isCreating) {
            let updateResult;
            if (process.env.NODE_ENV === 'production') {
                updateResult = await updateCourse(
                    selectedCourseId,
                    lastSelectedPublic,
                    marathonTitle.trim(),
                    marathonDesc.trim(),
                    category,
                    isAvatarEdited ? avatarId : undefined,
                    isCoverEdited ? coverId : undefined,
                    isVideoEdited ? videoId : undefined,
                );
            } else {
                updateResult = {};
                updateResult.result = 'success';
                updateResult.data = createCourseMock();
            }

            if (updateResult.result === 'success') {
                dispatch(createSetMarathonTitle(marathonTitle.trim()));
                dispatch(createSetMarathonDesc(marathonDesc.trim()));
                dispatch(createSetIsCopied(false));
                dispatch(
                    marathonsUpdateMarathon(
                        marathonStatus,
                        selectedCourseId,
                        marathonTitle.trim(),
                        marathonDesc.trim(),
                        avatar,
                        cover,
                        category,
                        selectTitle,
                        videoId,
                        videoLink,
                    ),
                );
                dispatch(push('/marathon-about'));
            } else {
                if (updateResult.e.error_data.error_reason.error_code === 100) {
                    dispatch(openModal(<ModalError title="Одно из полей было заполнено неверно." />));
                } else {
                    dispatch(
                        openModal(
                            <ModalError title="Произошла ошибка, из-за которой не удалось сохранить марафон. Попробуйте еще раз." />,
                        ),
                    );
                }
            }

            setIsCreating(false);
        }
    };

    const checkVideoLink = async () => {
        if (videoLink.length > 0) {
            // Проверяем что вставлена верная ссылка
            const preparedLink = prepareLink(videoLink);
            if (!preparedLink) {
                setVideoError('Поддерживаются только видео ВКонтакте');
                clearVideoOwnerAndId();
                return;
            }

            // Проверяем, что видео принадлежит группе
            if (+preparedLink.owner !== +lastSelectedPublic) {
                setVideoError('Необходимо вставить видео, принадлежашее выбранной группе');
                clearVideoOwnerAndId();
                return;
            }

            // Получаем ссылку через апи
            clearInterval(getVideoUrlTimeout);
            setGetVideoUrlTimeout(
                setTimeout(async () => {
                    if (process.env.NODE_ENV === 'production') {
                        currentRequestId.current = +getNewRequestId();
                        const videoResult = await getVideoUrl(
                            currentRequestId.current,
                            preparedLink.owner,
                            preparedLink.id,
                        );

                        if (
                            videoResult.result === 'success' &&
                            videoResult.data.request_id === currentRequestId.current
                        ) {
                            dispatch(createSetVideoPreviewUrl(videoResult.data.response.url));
                        }

                        resizeFrame();
                    } else {
                        dispatch(createSetVideoPreviewUrl(videoUrlMock().response.url));
                    }
                }, 1000),
            );

            dispatch(createSetVideoOwnerAndId(preparedLink.owner, preparedLink.id));
            setVideoError('');
        } else {
            setVideoError('');
        }
    };

    const prepareLink = link => {
        let url;

        if (link.indexOf('vk.com') === -1) {
            return false;
        }

        try {
            url = new URL(link);
        } catch (e) {
            return false;
        }

        let video = '';
        if (url.searchParams.get('z') !== null) {
            video = url.searchParams.get('z').split('/')[0];
        } else {
            video = url.pathname.substr(1, 100);
        }
        if (video.indexOf('video') === -1) {
            return false;
        }

        const ownerStart = video.indexOf('video') + 5;
        const ownerEnd = video.indexOf('_') + 1;
        const owner = video.substr(ownerStart, ownerEnd - 6);
        const endLength = video.indexOf('%') === -1 ? 100 : video.indexOf('%');
        const id = video.substr(ownerEnd, endLength);

        return { owner, id };
    };

    const checkFieldsIsNotFilled = () => {
        if (!isOpenSettings) {
            return (
                videoError !== '' ||
                avatarId === 0 ||
                coverId === 0 ||
                category === null ||
                marathonTitle.length === 0 ||
                marathonDesc.length === 0
            );
        } else {
            return (
                videoError !== '' ||
                (avatarId === 0 && isAvatarEdited) ||
                (coverId === 0 && isCoverEdited) ||
                category === null ||
                marathonTitle.length === 0 ||
                marathonDesc.length === 0
            );
        }
    };

    const renderBreadcumbs = () => {
        const breadcumbs = [];

        breadcumbs.push(<BreadcrumbsItem key="brc-1" title="VK Edu" link="marathons" />);
        if (!isOpenSettings) {
            breadcumbs.push(<BreadcrumbsItem key="brc-1" title="Новый марафон" link="course-create" />);
        } else {
            breadcumbs.push(<BreadcrumbsItem key="brc-2" title={createTitle} link="marathon-about" />);
            breadcumbs.push(<BreadcrumbsItem key="brc-3" title="Настройки" link="course-create" />);
        }

        return breadcumbs;
    };

    return (
        <Block breadcrumbs={renderBreadcumbs()} aside={!isOpenSettings ? <AuthorDropdown /> : ''}>
            <div className="course-create">
                <div className="course-create--content">
                    <FormGroup>
                        <FormLabel title="Название">
                            <input onChange={setTitle} defaultValue={marathonTitle} maxLength={128} />
                        </FormLabel>
                        <FormLabel title="Категория">
                            <Select
                                items={prepareCategoriesForSelect()}
                                defaultTitle={selectTitle}
                                defaultValue={category !== 0 ? category : undefined}
                                maxHeight={8}
                                onSelect={setCategory}
                            />
                        </FormLabel>
                        <FormLabel title="Описание">
                            <textarea onChange={setDesc} defaultValue={marathonDesc} maxLength={512} />
                        </FormLabel>
                        <FormLabel title="Изображения">
                            <FormLoadImg avatar={avatar} cover={cover} />
                        </FormLabel>
                        <FormLabel title="Видео">
                            <FormVideo error={videoError} />
                        </FormLabel>
                    </FormGroup>
                </div>
                <div className="course-create--bottom">
                    {!isOpenSettings && (
                        <ButtonWithLoader
                            disabled={checkFieldsIsNotFilled()}
                            isLoading={isCreating}
                            onClickFunc={createNewCourse}
                        >
                            Создать
                        </ButtonWithLoader>
                    )}
                    {isOpenSettings && (
                        <ButtonWithLoader
                            disabled={checkFieldsIsNotFilled()}
                            isLoading={isCreating}
                            onClickFunc={updateThisCourse}
                        >
                            Сохранить
                        </ButtonWithLoader>
                    )}
                </div>
            </div>
        </Block>
    );
};

export default PanelMarathonCreate;
