import React from 'react';
import PanelWelcome from '../containers/register/PanelWelcome';
import PanelWizardCreateStep1 from '../containers/register/PanelWizardCreateStep1';
import PanelWizardCreatePublic from '../containers/register/PanelWizardCreatePublic';
import PanelWizardCreateStep2 from '../containers/register/PanelWizardCreateStep2';
import PanelMarathons from '../containers/marathons/PanelMarathons';
import PanelEmpty from '../containers/PanelEmpty';
import PanelMarathonCreate from '../containers/create/PanelMarathonCreate';
import PanelMarathonAbout from '../containers/create/PanelMarathonAbout';
import PanelMarathonCreateTest from '../containers/create/PanelMarathonCreateTest';

const Routing = () => {
    const getPanel = page => {
        switch (page) {
            default:
            case '':
                return <PanelEmpty />;
            case 'welcome':
                return <PanelWelcome />;
            case 'wizard-create-public':
                return <PanelWizardCreatePublic />;
            case 'wizard-create-step-1':
                return <PanelWizardCreateStep1 />;
            case 'wizard-create-step-2':
                return <PanelWizardCreateStep2 />;
            case 'marathons':
                return <PanelMarathons />;
            case 'marathon-create':
                return <PanelMarathonCreate />;
            case 'marathon-about':
                return <PanelMarathonAbout />;
            case 'marathon-create-test':
                return <PanelMarathonCreateTest />;
        }
    };

    return { getPanel };
};

export default Routing;
