import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import {
    createSetIsVideoEdited,
    createSetVideoLink,
    createSetVideoOwnerAndId,
    createSetVideoPreviewUrl,
} from '../store/create/actions';
import { resizeFrame } from '../services/vk';
import '../../styles/form-video.scss';

const FormVideo = ({ error }) => {
    const dispatch = useDispatch();
    const videoPreviewUrl = useSelector(state => state.create.videoPreviewUrl);
    const videoLink = useSelector(state => state.create.videoLink);

    const changeHandler = e => {
        dispatch(createSetIsVideoEdited(true));
        dispatch(createSetVideoLink(e.target.value));
    };

    const clearVideoHandler = () => {
        dispatch(createSetIsVideoEdited(true));
        dispatch(createSetVideoPreviewUrl(''));
        dispatch(createSetVideoLink(''));
        dispatch(createSetVideoOwnerAndId(0, 0));
        resizeFrame();
    };

    const videoInput = (
        <>
            <input
                className={error !== '' ? 'error' : ''}
                onChange={changeHandler}
                placeholder="Ссылка на видео ВКонтакте"
                value={videoLink}
            />
            <span className="error-text">{error}</span>
        </>
    );

    const renderVideo = () => (
        <iframe src={videoPreviewUrl} width="400" height="228" frameBorder="0" allowFullScreen></iframe>
    );

    const hasVideo = (
        <div className="video-block">
            <Icon16Cancel fill="fff" onClick={clearVideoHandler} />
            {renderVideo()}
        </div>
    );

    return videoPreviewUrl !== '' ? hasVideo : videoInput;
};

FormVideo.propTypes = {
    error: PropTypes.string,
};

export default FormVideo;
