import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import Block from '../../components/Block';
import { goToTop, resizeFrame } from '../../services/vk';
import '../../../styles/welcome.scss';

const PanelWelcome = () => {
    const dispatch = useDispatch();
    const isStartInfoLoaded = useSelector(state => state.globals.isStartInfoLoaded);
    const publics = useSelector(state => state.author.publics);

    useEffect(() => {
        resizeFrame();
    }, []);

    const openWizard = () => {
        // Если администрируемых групп нет, то выводим белый жкран
        if (publics.length === 0) {
            dispatch(push('/wizard-create-public'));
        } else {
            dispatch(push('/wizard-create-step-1'));
        }
        goToTop();
    };

    return (
        <Block
            breadcrumbs={
                <>
                    <BreadcrumbsItem large title="VK Edu" link="" />
                </>
            }
        >
            <div className="welcome welcome-one welcome--center">
                <div className="welcome--image" />
                <div className="welcome--title">Делитесь знаниями</div>
                <div className="welcome--subtitle">
                    Создайте свой образовательный марафон
                    <br /> ВКонтакте — мы поможем найти студентов.
                </div>
                <Button onClick={openWizard} disabled={!isStartInfoLoaded}>
                    Создать марафон
                </Button>
            </div>
        </Block>
    );
};

export default PanelWelcome;
