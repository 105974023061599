const logger = () => {
    let fLogger;
    if (process.env.NODE_ENV !== process.env.REACT_APP_PROD_STATUS) {
        fLogger = store => next => action => {
            console.log('dispatching', action);
            return next(action);
        };
    } else {
        fLogger = store => next => action => {
            return next(action);
        };
    }
    return fLogger;
};

export default logger;
