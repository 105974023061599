import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon24Dropdown from '@vkontakte/icons/dist/24/dropdown';
import CustomScroll from 'react-custom-scroll';
import SelectItem from './SelectItem';
import { randomInteger } from '../services/_functions';
import '../../styles/select.scss';

const itemHeight = 25;

const Select = ({ defaultTitle, defaultValue, items, onSelect, maxHeight }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [title, setTitle] = useState(defaultTitle);
    const [value, setValue] = useState(defaultValue !== undefined ? defaultValue : null);
    const [height, setHeight] = useState('auto');
    const wrapperRef = useRef(null);

    const keySeed = randomInteger(0, 9999999999);

    useEffect(() => {
        const handleClickOutside = event => {
            if (isOpened) {
                if (wrapperRef && !wrapperRef.current.contains(event.target)) {
                    setIsOpened(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpened]);

    useEffect(() => {
        if (maxHeight > items.length) {
            setHeight(maxHeight * itemHeight + itemHeight / 2);
        }
    }, [items]);

    useEffect(() => {
        setTitle(defaultTitle);
    }, [defaultTitle]);

    const toggleSelect = () => {
        setIsOpened(!isOpened);
    };

    const setTitleAndValue = (selectedTitle, selectedValue) => {
        setTitle(selectedTitle);
        setValue(selectedValue);
        setIsOpened(false);
        if (onSelect !== undefined) {
            onSelect(selectedValue);
        }
    };

    const renderList = () =>
        items.map((item, num) => (
            <SelectItem
                key={`si-${keySeed}${num}`}
                title={item.title}
                value={item.value}
                setTitleAndValue={setTitleAndValue}
            />
        ));

    return (
        <div ref={wrapperRef} className={`select${isOpened ? ' select--opened' : ''}`} onClick={toggleSelect}>
            <div className="select--label">
                <span>{title}</span> <Icon24Dropdown width={12} height={12} fill="var(--icon_tertiary)" />
            </div>
            <div className="select--items" style={{ height }}>
                <CustomScroll heightRelativeToParent="100%">{renderList()}</CustomScroll>
            </div>
        </div>
    );
};

Select.propTypes = {
    defaultTitle: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.array,
    onSelect: PropTypes.func,
    maxHeight: PropTypes.number,
};

export default Select;
