import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/dropdown.scss';

const SelectItem = ({ title, value, setTitleAndValue }) => {
    const select = () => {
        setTitleAndValue(title, value);
    };

    return (
        <div className="select--item" onClick={select}>
            {title}
        </div>
    );
};

SelectItem.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setTitleAndValue: PropTypes.func,
};

export default SelectItem;
