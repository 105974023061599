import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@vkontakte/vkui/dist/components/Radio/Radio';
import { authorSelectPublic } from '../store/author/actions';
import '../../styles/radio-icon.scss';

const RadioIconItem = ({ icon, title, id }) => {
    const dispatch = useDispatch();
    const selectedPublicId = useSelector(state => state.author.selectedPublicId);

    const selectHandler = () => {
        dispatch(authorSelectPublic(id, title, icon));
    };

    return (
        <Radio name="public" className="radio-icon" onClick={selectHandler} defaultChecked={selectedPublicId === id}>
            <div className="radio-icon--icon" style={{ backgroundImage: `url(${icon})` }} />
            <div>{title}</div>
        </Radio>
    );
};

RadioIconItem.propTypes = {
    id: PropTypes.number,
    icon: PropTypes.string,
    title: PropTypes.string,
};

export default RadioIconItem;
