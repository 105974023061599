import * as types from './actionTypes';

export const authorSelectPublic = (publicId, publicTitle, publicAva) => ({
    type: types.AUTHOR_SELECT_PUBLIC,
    publicId,
    publicTitle,
    publicAva,
});

export const authorSetPublics = publics => ({
    type: types.AUTHOR_SET_PUBLICS,
    publics,
});

export const authorSetMarathonedPublics = publics => ({
    type: types.AUTHOR_SET_MARATHONED_PUBLICS,
    publics,
});

export const authorAddMarathonedPublic = marathonedPublic => ({
    type: types.AUTHOR_ADD_MARATHONED_PUBLIC,
    marathonedPublic,
});

export const authorUpdateFilteredPublics = () => ({
    type: types.AUTHOR_UPDATE_FILTERED_PUBLICS,
});

export const authorUpdateMarathonedFilledPublics = () => ({
    type: types.AUTHOR_UPDATE_MARATHONED_FILLED_PUBLICS,
});

export const authorSetIsShowMoreOpenedStatus = isShowMoreOpened => ({
    type: types.AUTHOR_SET_IS_SHOW_MORE_OPEN_STATUS,
    isShowMoreOpened,
});

export const authorSetTypes = authorTypes => ({
    type: types.AUTHOR_SET_TYPES,
    authorTypes,
});

export const authorSelectAuthorType = authorType => ({
    type: types.AUTHOR_SELECT_TYPE,
    authorType,
});
