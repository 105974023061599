import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/form-label.scss';

const FormLabel = ({ title, children }) => {
    return (
        <div className="form-label">
            <label>{title}</label>
            {children}
        </div>
    );
};

FormLabel.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default FormLabel;
