import * as types from './actionTypes';

const initialState = {
    selectedPublicId: 0,
    selectedPublicTitle: '',
    selectedPublicAva: 0,
    isShowMoreOpened: false,
    authorTypes: [],
    selectedAuthorType: '',
    publics: [],
    marathonedPublics: [],
    marathonedFilledPublics: [],
    filteredPublics: [],
};

export const author = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTHOR_SELECT_PUBLIC:
            return {
                ...state,
                selectedPublicId: action.publicId,
                selectedPublicTitle: action.publicTitle,
                selectedPublicAva: action.publicAva,
            };

        case types.AUTHOR_SET_PUBLICS:
            return {
                ...state,
                publics: [...action.publics],
            };

        case types.AUTHOR_SET_MARATHONED_PUBLICS:
            return {
                ...state,
                marathonedPublics: [...action.publics],
            };

        case types.AUTHOR_ADD_MARATHONED_PUBLIC:
            return {
                ...state,
                marathonedPublics: [...state.marathonedPublics, action.marathonedPublic],
            };

        case types.AUTHOR_UPDATE_FILTERED_PUBLICS: {
            const newFilteredPublics = [];
            for (let i = 0; i < state.publics.length; i++) {
                let isIn = false;
                for (let j = 0; j < state.marathonedPublics.length; j++) {
                    if (state.publics[i].id === -state.marathonedPublics[j].id) {
                        isIn = true;
                    }
                }
                if (!isIn) {
                    newFilteredPublics.push(state.publics[i]);
                }
            }

            return {
                ...state,
                filteredPublics: newFilteredPublics,
            };
        }

        case types.AUTHOR_UPDATE_MARATHONED_FILLED_PUBLICS: {
            const newMarathonedFilledPublics = [];
            for (let i = 0; i < state.marathonedPublics.length; i++) {
                const tmpPublic = { ...state.marathonedPublics[i] };
                for (let j = 0; j < state.publics.length; j++) {
                    if (state.marathonedPublics[i].id === -state.publics[j].id) {
                        tmpPublic.info = state.publics[j];
                    }
                }
                newMarathonedFilledPublics.push(tmpPublic);
            }

            return {
                ...state,
                marathonedFilledPublics: newMarathonedFilledPublics,
            };
        }

        case types.AUTHOR_SET_IS_SHOW_MORE_OPEN_STATUS:
            return {
                ...state,
                isShowMoreOpened: action.isShowMoreOpened,
            };

        case types.AUTHOR_SET_TYPES:
            return {
                ...state,
                authorTypes: action.authorTypes,
            };

        case types.AUTHOR_SELECT_TYPE:
            return {
                ...state,
                selectedAuthorType: action.authorType,
            };

        default:
            return state;
    }
};
