import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import RatingStars from './RatingStars';
import ModalDel from './ModalDel';
import { openModal } from '../store/globals/actions';
import { createDeleteStep, createReplaceStep } from '../store/create/actions';
import { deleteStep, getCourseStep, goToTop, openArticleEditor, resizeFrame } from '../services/vk';
import '../../styles/program-item.scss';
import { testSetCurrentStep } from '../store/test/actions';

const ProgramItem = ({ title, rating, stepId, articleId, hasTest }) => {
    const dispatch = useDispatch();
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const selectedCourseId = useSelector(state => state.create.selectedCourseId);
    const marathonStatus = useSelector(state => state.create.marathonStatus);
    const elementRef = useRef(null);

    const deleteStepFunc = async () => {
        if (process.env.NODE_ENV === 'production') {
            const deleteResult = await deleteStep(lastSelectedPublic, selectedCourseId, stepId);
            if (deleteResult.result === 'success') {
                dispatch(createDeleteStep(stepId));
            }
        } else {
            dispatch(createDeleteStep(stepId));
        }
        resizeFrame();
    };

    const openModalDel = () => {
        dispatch(
            openModal(
                <ModalDel
                    title="упражнение"
                    deleteFunc={deleteStepFunc}
                    top={elementRef.current.getBoundingClientRect().top - 250}
                />,
            ),
        );
    };

    const openTestCreate = () => {
        dispatch(testSetCurrentStep(stepId));
        dispatch(push('/marathon-create-test'));
        goToTop();
    };

    const editArticle = async () => {
        await openArticleEditor(lastSelectedPublic, selectedCourseId, articleId);
        const getStep = await getCourseStep(lastSelectedPublic, selectedCourseId, stepId);
        if (getStep.result === 'success') {
            dispatch(createReplaceStep(getStep.data.step));
        }
    };

    return (
        <div className="program--item" ref={elementRef}>
            <div className="program--top">
                <div className="program--title">{title}</div>
                <RatingStars size={9} rating={rating} />
            </div>
            <div className="program--bottom">
                <div onClick={editArticle}>Редактировать</div>
                {marathonStatus === 'draft' && (
                    <>
                        &nbsp;<span>·</span>&nbsp;
                        <div onClick={openModalDel}>Удалить</div>
                    </>
                )}
                {marathonStatus !== 'draft' && hasTest && (
                    <>
                        &nbsp;<span>·</span>&nbsp;
                        <div onClick={openTestCreate}>Редактировать тест</div>
                    </>
                )}
                {marathonStatus === 'draft' && (
                    <>
                        &nbsp;<span>·</span>&nbsp;
                        <div onClick={openTestCreate}>{!hasTest ? 'Добавить тест' : 'Редактировать тест'}</div>
                    </>
                )}
            </div>
        </div>
    );
};

ProgramItem.propTypes = {
    title: PropTypes.string,
    rating: PropTypes.number,
    stepId: PropTypes.number,
    articleId: PropTypes.number,
    hasTest: PropTypes.bool,
};

export default ProgramItem;
