import * as types from './actionTypes';

export const marathonsSetTabIndex = tabIndex => ({
    type: types.MARATHONS_SET_TAB_INDEX,
    tabIndex,
});

export const marathonsSetPaginationPage = (tabIndex, pageNum) => ({
    type: types.MARATHONS_SET_PAGE_NUM,
    tabIndex,
    pageNum,
});

export const marathonsSetCourses = (tabIndex, courses, total) => ({
    type: types.MARATHONS_SET_COURSES,
    tabIndex,
    courses,
    total,
});

export const marathonsSetStopLoading = tabIndex => ({
    type: types.MARATHONS_SET_COURSES_IS_LOADING,
    tabIndex,
});

export const marathonsResetTabPageAndCourses = () => ({
    type: types.MARATHONS_RESET_TAB_PAGE_AND_COURSES,
});

export const marathonsClearCoursesAndPageNum = tabIndex => ({
    type: types.MARATHONS_CLEAR_COURSES_AND_PAGE_NUM,
    tabIndex,
});

export const marathonsUpdateMarathon = (
    publishType,
    courseId,
    title,
    description,
    avatar,
    cover,
    category,
    categoryTranslate,
    videoId,
    videoPreviewUrl,
) => ({
    type: types.MARATHONS_UPDATE_MARATHON,
    publishType,
    courseId,
    title,
    description,
    avatar,
    cover,
    category,
    categoryTranslate,
    videoId,
    videoPreviewUrl,
});
