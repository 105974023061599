import axios from 'axios';

export const uploadImageToServer = (url, image) => {
    const successFunc = response => {
        return { result: 'success', data: response };
    };

    const failFunc = error => {
        return { result: 'fail', error };
    };

    const preparedPostData = new FormData();
    preparedPostData.append('file', image);

    // Делаем запрос
    return axios({
        method: 'post',
        url,
        data: preparedPostData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then(result => successFunc(result))
        .catch(error => failFunc(error));
};
