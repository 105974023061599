import * as types from './actionTypes';

const initialState = {
    isAddedLeft: false,
    lastSelectedPublic: 0,
};

export const storage = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_STORAGE: {
            const newStorage = {};
            action.storage.keys.forEach(st => {
                if (st.value === '') {
                    newStorage[st.key] = state[st.key];
                } else {
                    newStorage[st.key] = convertDataTypes(st.key, st.value);
                }
            });
            return {
                ...state,
                ...newStorage,
            };
        }

        case types.SET_ONE_STORAGE: {
            const newStorage = { ...state };
            newStorage[action.key] = convertDataTypes(action.key, action.value);
            return {
                ...newStorage,
            };
        }

        default:
            return state;
    }
};

const convertDataTypes = (key, value) => {
    switch (key) {
        case 'isAddedLeft':
            return value === 'true';
        case 'lastSelectedPublic':
            return +value;
    }
};
