import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Icon28Camera from '@vkontakte/icons/dist/28/camera';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import ModalLoadImg from './ModalLoadImg';
import { openModal } from '../store/globals/actions';
import {
    createSetAvatar,
    createSetAvatarId,
    createSetCover,
    createSetCoverId,
    createSetIsAvatarEdited,
    createSetIsCoverEdited,
} from '../store/create/actions';
import '../../styles/form-load-img.scss';

const FormLoadImg = ({ avatar, cover }) => {
    const dispatch = useDispatch();

    const openModalLoadAvatar = () => {
        dispatch(
            openModal(
                <ModalLoadImg
                    isCover={false}
                    title="Загрузка фотографии"
                    text="Загрузите основное изображение марафона."
                    sizeText="224х224"
                />,
            ),
        );
    };

    const openModalLoadCover = () => {
        dispatch(
            openModal(
                <ModalLoadImg
                    isCover
                    title="Загрузка обложки"
                    text="Загрузите обложку марафона."
                    sizeText="1590х400"
                />,
            ),
        );
    };

    const clearAvatar = () => {
        dispatch(createSetIsAvatarEdited(true));
        dispatch(createSetAvatar(null));
        dispatch(createSetAvatarId(0));
    };

    const clearCover = () => {
        dispatch(createSetIsCoverEdited(true));
        dispatch(createSetCover(null));
        dispatch(createSetCoverId(0));
    };

    const loadAvatar = (
        <>
            <div className="form-load-img--add" onClick={openModalLoadAvatar}>
                <Icon28Camera fill="9bb4cc" />
                <span>Загрузить аватар</span>
            </div>
        </>
    );
    const loadCover = (
        <>
            <div className="form-load-img--add" onClick={openModalLoadCover}>
                <Icon28Camera fill="9bb4cc" />
                <span>Загрузить обложку</span>
            </div>
        </>
    );

    return (
        <div className="form-load-img">
            <div className="form-load-img--avatar">
                {avatar ? (
                    <div className="form-load-img--image" style={{ backgroundImage: `url(${avatar})` }} />
                ) : (
                    loadAvatar
                )}
                {avatar ? <Icon16Cancel fill="fff" onClick={clearAvatar} /> : ''}
            </div>
            <div className="form-load-img--cover">
                {cover ? (
                    <div className="form-load-img--image" style={{ backgroundImage: `url(${cover})` }} />
                ) : (
                    loadCover
                )}
                {cover ? <Icon16Cancel fill="fff" onClick={clearCover} /> : ''}
            </div>
        </div>
    );
};

FormLoadImg.propTypes = {
    avatar: PropTypes.string,
    cover: PropTypes.string,
};

export default FormLoadImg;
