import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import {
    archiveCourse,
    createCourseStep,
    getAuthorCourses,
    getCourseStep,
    getCourseSteps,
    goToTop,
    publishCourse,
    resizeFrame,
    unarchiveCourse,
} from '../../services/vk';
import { getSelectedPublicInfo, numberWithKM, sleep, wordPad } from '../../services/_functions';
import {
    createAddCourseSteps,
    createCopySettings,
    createRestoreSettings,
    createSetCourseSteps,
    createSetIsAvatarEdited,
    createSetIsCoverEdited,
    createSetIsOpenSettings,
    createSetIsVideoEdited,
    createSetMarathonsAmount,
    createSetMarathonStatus,
} from '../../store/create/actions';
import { authorCoursesArrMock, getStepsMock } from '../../services/mock';
import { marathonsClearCoursesAndPageNum } from '../../store/marathons/actions';
import { openModal } from '../../store/globals/actions';
import { testReset } from '../../store/test/actions';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import Block from '../../components/Block';
import BlockHead from '../../components/BlockHead';
import ProgramItemList from '../../components/ProgramItemList';
import RatingStars from '../../components/RatingStars';
import ModalError from '../../components/ModalError';
import DropdownMenuItem from '../../components/DropdownMenuItem';
import DropdownMenu from '../../components/DropdownMenu';
import '../../../styles/marathon-about.scss';

const PanelMarathonAbout = () => {
    const dispatch = useDispatch();
    const marathonTitle = useSelector(state => state.create.marathonTitle);
    const marathonDesc = useSelector(state => state.create.marathonDesc);
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const selectedCourseId = useSelector(state => state.create.selectedCourseId);
    const marathonedFilledPublics = useSelector(state => state.author.marathonedFilledPublics);
    const marathonStatus = useSelector(state => state.create.marathonStatus);
    const students = useSelector(state => state.create.students);
    const rating = useSelector(state => state.create.rating);
    const cover = useSelector(state => state.create.cover);
    const marathonsAmount = useSelector(state => state.create.marathonsAmount);
    const videoPreviewUrl = useSelector(state => state.create.videoPreviewUrl);
    const steps = useSelector(state => state.create.steps);
    const isCopied = useSelector(state => state.create.isCopied);
    const isMarathonJustCreated = useSelector(state => state.create.isMarathonJustCreated);
    const [isLoadingSteps, setIsLoadingSteps] = useState(true);
    const [isCreatingTest, setIsCreatingTest] = useState(false);
    const publicInfo = getSelectedPublicInfo(lastSelectedPublic, marathonedFilledPublics);

    useEffect(() => {
        if (isCopied) {
            dispatch(createRestoreSettings());
        }

        if (process.env.NODE_ENV === 'production') {
            (async () => {
                // Общее количество курсом
                const authorCourses = await getAuthorCourses(lastSelectedPublic, 1, 1);
                if (authorCourses.result === 'success') {
                    dispatch(createSetMarathonsAmount(authorCourses.data.total));
                }

                // Упражнения
                setIsLoadingSteps(true);
                const courseSteps = await getCourseSteps(lastSelectedPublic, selectedCourseId);
                if (courseSteps.result === 'success') {
                    dispatch(createSetCourseSteps(courseSteps.data.items));
                }
                setIsLoadingSteps(false);
                resizeFrame();
            })();
        } else {
            dispatch(createSetMarathonsAmount(authorCoursesArrMock().total));
            dispatch(createSetCourseSteps(getStepsMock()));
            setIsLoadingSteps(false);
        }

        // Если марафон только создан, сбрасываем список марафонов, чтобы он загрузился снова
        if (isMarathonJustCreated) {
            dispatch(marathonsClearCoursesAndPageNum(2));
        }

        dispatch(testReset());

        resizeFrame();
    }, []);

    const hasWarning = (
        <div className="marathon-about--notification">
            <b>В этом марафоне могут участвовать студенты</b>
            Вы можете внести только небольшие изменения, например, добавить картинку или исправить текст.
        </div>
    );

    const publishCourseAction = async () => {
        const publishResult = await publishCourse(lastSelectedPublic, selectedCourseId);
        if (publishResult.result === 'fail') {
            if (publishResult.e.error_data.error_reason.error_code === 3403) {
                dispatch(openModal(<ModalError title="Сначала опубликуйте все упражнения" top={300} />));
            } else {
                dispatch(openModal(<ModalError title="Произошла неизвестная ошибка" top={300} />));
            }
        } else {
            dispatch(createSetMarathonStatus('published'));
            dispatch(marathonsClearCoursesAndPageNum(1));
            dispatch(marathonsClearCoursesAndPageNum(2));
        }
        resizeFrame();
    };

    const archiveCourseAction = async () => {
        const archiveResult = await archiveCourse(lastSelectedPublic, selectedCourseId);
        if (archiveResult.result === 'fail') {
            dispatch(openModal(<ModalError title="Произошла неизвестная ошибка" top={300} />));
        } else {
            dispatch(createSetMarathonStatus('archived'));
            dispatch(marathonsClearCoursesAndPageNum(1));
            dispatch(marathonsClearCoursesAndPageNum(3));
        }
        resizeFrame();
    };

    const unarchiveCourseAction = async () => {
        const unarchiveResult = await unarchiveCourse(lastSelectedPublic, selectedCourseId);
        if (unarchiveResult.result === 'fail') {
            dispatch(openModal(<ModalError title="Произошла неизвестная ошибка" top={300} />));
        } else {
            dispatch(createSetMarathonStatus('published'));
            dispatch(marathonsClearCoursesAndPageNum(1));
            dispatch(marathonsClearCoursesAndPageNum(3));
        }
        resizeFrame();
    };

    const openMarathonSettingsAction = () => {
        dispatch(createSetIsAvatarEdited(false));
        dispatch(createSetIsCoverEdited(false));
        dispatch(createSetIsVideoEdited(false));
        dispatch(createCopySettings());
        dispatch(createSetIsOpenSettings(true));
        dispatch(push('/marathon-create'));
        goToTop();
    };

    const renderMenu = () => {
        const menu = [];
        switch (marathonStatus) {
            case 'published':
                menu.push(
                    <DropdownMenuItem key="dmi-publish" clickAction={archiveCourseAction}>
                        Архивировать
                    </DropdownMenuItem>,
                );
                break;
            case 'draft':
                menu.push(
                    <DropdownMenuItem key="dmi-draft" clickAction={publishCourseAction}>
                        Опубликовать
                    </DropdownMenuItem>,
                );
                break;
            case 'archived':
                menu.push(
                    <DropdownMenuItem key="dmi-archived" clickAction={unarchiveCourseAction}>
                        Разархивировать
                    </DropdownMenuItem>,
                );
                break;
        }
        menu.push(
            <DropdownMenuItem key="dmi-settings" clickAction={openMarathonSettingsAction}>
                Настройки
            </DropdownMenuItem>,
        );

        return menu;
    };

    const renderMenuTitle = () => {
        switch (marathonStatus) {
            case 'published':
                return 'Опубликован';
            case 'draft':
                return 'Черновик';
            case 'archived':
                return 'В архиве';
        }
    };

    const renderVideo = () => {
        return <iframe src={videoPreviewUrl} width="367" height="212" frameBorder="0" allowFullScreen></iframe>;
    };

    const createStep = async () => {
        setIsCreatingTest(true);
        const createStepId = await createCourseStep(lastSelectedPublic, selectedCourseId);

        if (createStepId.result === 'success') {
            const stepId = createStepId.data.step_id;
            const getStep = await getCourseStep(lastSelectedPublic, selectedCourseId, stepId);

            if (getStep.result === 'success') {
                dispatch(createAddCourseSteps(getStep.data.step));
                resizeFrame();
            }
        } else {
            dispatch(
                openModal(
                    <ModalError title="Произошла неизвестная ошибка при создании шага. Пропробуйте снова." top={350} />,
                ),
            );
        }
        await sleep(333);
        setIsCreatingTest(false);
    };

    return (
        <>
            <Block
                breadcrumbs={
                    <>
                        <BreadcrumbsItem title="VK Edu" link="marathons" />
                        <BreadcrumbsItem title={marathonTitle} />
                    </>
                }
            >
                <div className="marathon-about">
                    {marathonStatus === 'published' ? hasWarning : ''}
                    <div className="marathon-about--cover" style={{ backgroundImage: `url(${cover})` }} />
                    <div className="marathon-about--profile">
                        <div className="marathon-about--content">
                            <div className="marathon-about--title">{marathonTitle}</div>
                            <div className="marathon--action">
                                <DropdownMenu title={renderMenuTitle()}>{renderMenu()}</DropdownMenu>
                            </div>
                            <div className="marathon-about--subtitle">{marathonDesc}</div>
                            <div className="marathon-about--rating">
                                <RatingStars size={12} rating={rating} />
                                <span>{students + wordPad(students, ' студент', ' студента', ' студентов')}</span>
                            </div>
                        </div>
                        {videoPreviewUrl !== '' && <div className="marathon-about--video">{renderVideo()}</div>}
                    </div>
                    <div className="marathon-public">
                        <a href={`https://vk.com/group${publicInfo.id}`} target="_blank">
                            <div
                                className="marathon-public--avatar"
                                style={{ backgroundImage: `url(${publicInfo.photo_100})` }}
                            />
                        </a>
                        <div className="marathon-public--info">
                            <a href={`https://vk.com/club${publicInfo.id}`} target="_blank">
                                <div className="marathon-public--name">{publicInfo.name}</div>
                            </a>
                            <div className="marathon-public--stats">
                                {marathonsAmount + wordPad(marathonsAmount, ' марафон', ' марафона', ' марафонов')}
                                {publicInfo.members_count !== undefined
                                    ? '· ' +
                                      numberWithKM(publicInfo.members_count) +
                                      (publicInfo.members_count < 1000
                                          ? wordPad(
                                                publicInfo.members_count,
                                                ' подписчик',
                                                ' подписчика',
                                                ' подписчиков',
                                            )
                                          : ' подписчиков')
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Block>
            {!isLoadingSteps && (
                <BlockHead title="Программа">
                    {steps.length > 0 && (
                        <>
                            {steps.length < 42 && marathonStatus === 'draft' && (
                                <div className="add-step-block">
                                    <Button
                                        className="btn-add-step"
                                        mode="tertiary"
                                        before={<Icon24Add fill="5181b8" width={20} height={20} />}
                                        onClick={createStep}
                                        disabled={isCreatingTest}
                                    >
                                        Создать упражнение
                                    </Button>
                                </div>
                            )}
                            <ProgramItemList />
                        </>
                    )}
                    {steps.length === 0 && (
                        <div className="zero">
                            <div className="zero--title">В марафоне пока нет упражнений</div>
                            <Button onClick={createStep}>Создать упражнение</Button>
                        </div>
                    )}
                </BlockHead>
            )}
        </>
    );
};

export default PanelMarathonAbout;
