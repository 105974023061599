import React, { useState } from 'react';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import { useDispatch } from 'react-redux';
import { setOneStorage } from '../store/storage/actions';
import { addToLeftMenu, resizeFrame, setStorageKey } from '../services/vk';
import ButtonWithLoader from './ButtonWithLoader';
import '../../styles/block-connect.scss';

const ConnectPublic = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const hideClickHandler = () => {
        if (process.env.NODE_ENV === 'development') {
            dispatch(setOneStorage('isAddedLeft', 'true'));
            resizeFrame();
        } else {
            dispatch(setStorageKey('isAddedLeft', 'true'));
            resizeFrame();
        }
    };

    const addLeftClickHandler = async () => {
        if (process.env.NODE_ENV === 'development') {
            hideClickHandler();
        } else {
            setIsLoading(true);
            const addResult = await dispatch(addToLeftMenu());
            if (addResult) {
                hideClickHandler();
            }
            setIsLoading(false);
        }
    };

    return (
        <div className="block-connect">
            <div className="block-connect--image" />
            <div className="block-connect--info">
                <div className="block-connect--title">Сообщество успешно подключено!</div>
                <div className="block-connect--text">
                    Добавьте приложение в меню, чтобы всегда иметь к нему быстрый доступ.
                </div>
                <div className="block-connect--btn">
                    <ButtonWithLoader isLoading={isLoading} onClickFunc={addLeftClickHandler}>
                        Добавить в меню
                    </ButtonWithLoader>
                    <Button mode="secondary" onClick={hideClickHandler}>
                        Скрыть
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConnectPublic;
