import React from 'react';
import PropTypes from 'prop-types';
import { wordPad } from '../services/_functions';
import RatingStars from './RatingStars';

const MarathonItem = ({ onClick, image, title, desc, exercise, rating }) => {
    const el = document.getElementsByClassName('marathon-item--title');
    for (let i = 0; i < el.length; i++) {
        const height = el[i].offsetHeight;
        if (height > 20) {
            el[i].classList.add('one-row');
        }
    }

    return (
        <div className="marathon-item" onClick={onClick}>
            <div className="marathon-item--image" style={{ backgroundImage: `url(${image})` }} />
            <div className="marathon-item--content">
                <div className="marathon-item--info">
                    <div className="marathon-item--title">{title}</div>
                    {desc ? <div className="marathon-item--description">{desc}</div> : ''}
                </div>
                <div className="marathon-item--footer">
                    <div className="marathon-item--desc">
                        {exercise + wordPad(exercise, ' упражнение', ' упражнения', ' упражнений')}
                    </div>
                    <div className="marathon-home--icon">
                        <RatingStars size={9} rating={rating} />
                    </div>
                </div>
            </div>
        </div>
    );
};

MarathonItem.propTypes = {
    onClick: PropTypes.func,
    image: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    exercise: PropTypes.number,
    rating: PropTypes.number,
};

export default MarathonItem;
