import * as types from './actionTypes';

const initialState = {
    categories: [],
    avatar: null,
    avatarId: 0,
    cover: null,
    coverId: 0,
    marathonTitle: '',
    marathonDesc: '',
    category: null,
    videoLink: '',
    videoOwner: 0,
    videoId: 0,
    videoPreviewUrl: '',
    isAvatarEdited: false,
    isCoverEdited: false,
    isVideoEdited: false,
    selectedCourseId: 0,
    marathonStatus: 'draft',
    students: 0,
    rating: 0,
    marathonsAmount: 0,
    steps: [],
    isOpenSettings: false,
    copy: {
        avatar: null,
        avatarId: 0,
        cover: null,
        coverId: 0,
        marathonTitle: '',
        marathonDesc: '',
        category: null,
        videoLink: '',
        videoOwner: 0,
        videoId: 0,
        videoPreviewUrl: '',
    },
    isCopied: false,
};

export const create = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_SET_MARATHON_TITLE:
            return {
                ...state,
                marathonTitle: action.marathonTitle,
            };
        case types.CREATE_SET_MARATHON_DESC:
            return {
                ...state,
                marathonDesc: action.marathonDesc,
            };

        case types.CREATE_SET_COVER:
            return {
                ...state,
                cover: action.cover,
            };

        case types.CREATE_SET_AVATAR:
            return {
                ...state,
                avatar: action.avatar,
            };

        case types.CREATE_SET_COVER_ID:
            return {
                ...state,
                coverId: action.coverId,
            };

        case types.CREATE_SET_AVATAR_ID:
            return {
                ...state,
                avatarId: action.avatarId,
            };

        case types.CREATE_SET_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            };

        case types.CREATE_SELECT_CATEGORY:
            return {
                ...state,
                category: action.category,
            };

        case types.CREATE_SET_VIDEO_LINK:
            return {
                ...state,
                videoLink: action.videoLink,
            };

        case types.CREATE_SET_VIDEO_OWNER_AND_ID:
            return {
                ...state,
                videoOwner: action.videoOwner,
                videoId: action.videoId,
            };

        case types.CREATE_SET_VIDEO_PREVIEW_URL:
            return {
                ...state,
                videoPreviewUrl: action.videoPreviewUrl,
            };

        case types.CREATE_SET_SELECTED_COURSE_ID:
            return {
                ...state,
                selectedCourseId: action.selectedCourseId,
            };

        case types.CREATE_SET_MARATHONS_AMOUNT:
            return {
                ...state,
                marathonsAmount: action.marathonsAmount,
            };

        case types.CREATE_SET_COURSE_STEPS:
            return {
                ...state,
                steps: action.steps,
            };

        case types.CREATE_ADD_COURSE_STEPS:
            return {
                ...state,
                steps: [...state.steps, action.step],
            };

        case types.CREATE_SET_COURSE_INFO:
            return {
                ...state,
                marathonTitle: action.courseInfo.title.trim(),
                marathonDesc: action.courseInfo.description.trim(),
                avatar: action.courseInfo.avatar_photo[0].b.url,
                avatarId: 0,
                cover: action.courseInfo.cover_photo[0].b.url,
                coverId: 0,
                rating: action.courseInfo.rating,
                students: action.courseInfo.students_count,
                marathonStatus: action.courseInfo.type,
                selectedCourseId: action.courseInfo.course_id,
                category: action.courseInfo.categories[0].id,
                videoOwner: action.courseInfo.video_item_id !== undefined ? action.courseInfo.author_id : 0,
                videoId: action.courseInfo.video_item_id !== undefined ? action.courseInfo.video_item_id : 0,
                videoPreviewUrl: action.courseInfo.video_item_id !== undefined ? action.courseInfo.video_url : '',
                isAvatarEdited: false,
                isCoverEdited: false,
                isVideoEdited: false,
            };

        case types.CREATE_SET_MARATHON_STATUS:
            return {
                ...state,
                marathonStatus: action.marathonStatus,
            };

        case types.CREATE_SET_IS_MARATHON_JUST_CREATED:
            return {
                ...state,
                isMarathonJustCreated: action.isMarathonJustCreated,
            };

        case types.CREATE_DELETE_STEP: {
            const newSteps = [...state.steps].filter(step => step.step_id !== action.stepId);

            return {
                ...state,
                steps: [...newSteps],
            };
        }

        case types.CREATE_REPLACE_STEP: {
            const newSteps = [...state.steps];
            for (let i = 0; i < newSteps.length; i++) {
                if (newSteps[i].step_id === action.step.step_id) {
                    newSteps[i] = action.step;
                }
            }

            return {
                ...state,
                steps: [...newSteps],
            };
        }

        case types.CREATE_SET_IS_OPEN_SETTINGS:
            return {
                ...state,
                isOpenSettings: action.isOpenSettings,
            };

        case types.CREATE_COPY_SETTINGS:
            return {
                ...state,
                copy: {
                    avatar: state.avatar,
                    avatarId: state.avatarId,
                    cover: state.cover,
                    coverId: state.coverId,
                    marathonTitle: state.marathonTitle.trim(),
                    marathonDesc: state.marathonDesc.trim(),
                    category: state.category,
                    videoLink: state.videoLink,
                    videoOwner: state.videoOwner,
                    videoId: state.videoId,
                    videoPreviewUrl: state.videoPreviewUrl,
                },
                isCopied: true,
            };

        case types.CREATE_RESTORE_SETTINGS:
            return {
                ...state,
                avatar: state.copy.avatar,
                avatarId: state.copy.avatarId,
                cover: state.copy.cover,
                coverId: state.copy.coverId,
                marathonTitle: state.copy.marathonTitle,
                marathonDesc: state.copy.marathonDesc,
                category: state.copy.category,
                videoLink: state.copy.videoLink,
                videoOwner: state.copy.videoOwner,
                videoId: state.copy.videoId,
                videoPreviewUrl: state.copy.videoPreviewUrl,
                isCopied: false,
            };

        case types.CREATE_SET_IS_COPIED:
            return {
                ...state,
                isCopied: action.isCopied,
            };

        case types.CREATE_SET_IS_AVATAR_EDITED: {
            return {
                ...state,
                isAvatarEdited: action.isAvatarEdited,
            };
        }

        case types.CREATE_SET_IS_COVER_EDITED: {
            return {
                ...state,
                isCoverEdited: action.isCoverEdited,
            };
        }

        case types.CREATE_SET_IS_VIDEO_EDITED: {
            return {
                ...state,
                isVideoEdited: action.isVideoEdited,
            };
        }

        case types.CREATE_RESET_COURSE_INFO:
            return {
                ...state,
                avatar: null,
                avatarId: 0,
                cover: null,
                coverId: 0,
                marathonTitle: '',
                marathonDesc: '',
                category: null,
                videoLink: '',
                videoOwner: 0,
                videoId: 0,
                videoPreviewUrl: '',
                selectedCourseId: 0,
                marathonStatus: 'draft',
                students: 0,
                rating: 0,
                marathonsAmount: 0,
                steps: [],
                isOpenSettings: false,
                isAvatarEdited: false,
                isCoverEdited: false,
                isVideoEdited: false,
                copy: {
                    avatar: null,
                    avatarId: 0,
                    cover: null,
                    coverId: 0,
                    marathonTitle: '',
                    marathonDesc: '',
                    category: null,
                    videoLink: '',
                    videoOwner: 0,
                    videoId: 0,
                    videoPreviewUrl: '',
                },
                isCopied: false,
            };

        default:
            return state;
    }
};
