import * as types from './actionTypes';

export const setStorage = storage => ({
    type: types.SET_STORAGE,
    storage,
});

export const setOneStorage = (key, value) => ({
    type: types.SET_ONE_STORAGE,
    key,
    value,
});
