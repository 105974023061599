import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import Tabs from '@vkontakte/vkui/dist/components/Tabs/Tabs';
import TabsItem from '@vkontakte/vkui/dist/components/TabsItem/TabsItem';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import Block from '../../components/Block';
import ConnectPublic from '../../components/ConnectPublic';
import MarathonItemList from '../../components/MarathonItemList';
import Pagination from '../../components/Pagination';
import AuthorDropdown from '../../components/AuthorDropdown';
import { marathonsSetTabIndex } from '../../store/marathons/actions';
import { getMarathons } from '../../services/_functions';
import { goToTop, resizeFrame } from '../../services/vk';
import { createResetCourseInfo, createSetIsMarathonJustCreated } from '../../store/create/actions';
import '../../../styles/head-tabs.scss';

const PanelMarathons = () => {
    const dispatch = useDispatch();
    const tabIndex = useSelector(state => state.marathons.tabIndex);
    const pageNum = useSelector(state => state.marathons.pageNum);
    const isAddedLeft = useSelector(state => state.storage.isAddedLeft);
    const courses = useSelector(state => state.marathons.courses);
    const lastSelectedPublic = useSelector(state => state.storage.lastSelectedPublic);
    const currentRequestId = useRef(0);

    useEffect(() => {
        resizeFrame();
        dispatch(createResetCourseInfo());
        dispatch(
            getMarathons(currentRequestId, lastSelectedPublic, tabIndex, pageNum[tabIndex], courses[tabIndex].isLoaded),
        );
        dispatch(createSetIsMarathonJustCreated(false));
    }, []);

    const openMarathonCreate = () => {
        dispatch(push('/marathon-create'));
        goToTop();
    };

    const selectTab = num => {
        dispatch(marathonsSetTabIndex(num));
        dispatch(getMarathons(currentRequestId, lastSelectedPublic, num, pageNum[num], courses[num].isLoaded));
        resizeFrame();
    };

    const renderCreateMarathonButton = () => (
        <Button
            className="btn-add"
            mode="tertiary"
            before={<Icon24Add fill="5181b8" width={20} height={20} />}
            onClick={openMarathonCreate}
        >
            Создать марафон
        </Button>
    );

    return (
        <Block breadcrumbs={<BreadcrumbsItem large title="VK Edu" link="" />} aside={<AuthorDropdown />}>
            {!isAddedLeft && <ConnectPublic />}
            <Tabs mode="default" type="buttons" className="tabs-header">
                <TabsItem onClick={() => selectTab(1)} selected={tabIndex === 1}>
                    Марафоны
                </TabsItem>
                <TabsItem onClick={() => selectTab(2)} selected={tabIndex === 2}>
                    Черновики
                </TabsItem>
                <TabsItem onClick={() => selectTab(3)} selected={tabIndex === 3}>
                    Архив
                </TabsItem>
                <Pagination isTop />
            </Tabs>
            {courses[tabIndex].isLoaded && courses[tabIndex].total !== 0 && renderCreateMarathonButton()}
            <div className={`tabs-inner tabs-offset${isAddedLeft ? ' fixed-height' : ''}`}>
                <MarathonItemList />
            </div>
            <Pagination />
        </Block>
    );
};

PanelMarathons.propTypes = {
    routePush: PropTypes.func,
};

export default PanelMarathons;
