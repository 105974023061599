import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import Block from '../../components/Block';
import { resizeFrame } from '../../services/vk';
import '../../../styles/wizard.scss';

const PanelWizardCreatePublic = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        resizeFrame();
    }, []);

    const openWizardChoose = () => {
        dispatch(push('/wizard-create-step-1'));
    };

    return (
        <Block
            breadcrumbs={
                <>
                    <BreadcrumbsItem large title="VK Edu" link="" />
                </>
            }
        >
            <div className="wizard">
                <div className="wizard--image" />
                <div className="wizard--info">
                    <div className="wizard--title">Создайте сообщество</div>
                    <div className="wizard--subtitle">
                        Марафоны можно публиковать только от имени сообществ. Создайте сообщество, чтобы начать
                        публиковать марафоны.
                    </div>
                </div>
            </div>
            <div className="wizard--footer">
                <Button onClick={openWizardChoose}>Создать собщество</Button>
            </div>
        </Block>
    );
};

export default PanelWizardCreatePublic;
