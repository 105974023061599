import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/block.scss';

const BlockHead = ({ offset, title, before, aside, children }) => {
    return (
        <div className="block-head" style={{ marginTop: offset }}>
            {title ? (
                <>
                    <div className="header">
                        <span>{title}</span>
                        {before}
                        {aside}
                    </div>
                </>
            ) : (
                ''
            )}
            {children}
        </div>
    );
};

BlockHead.propTypes = {
    offset: PropTypes.number,
    title: PropTypes.string,
    before: PropTypes.node,
    aside: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default BlockHead;
