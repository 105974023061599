import React from 'react';
import { push } from 'react-router-redux';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import MarathonItem from './MarathonItem';
import { createResetCourseInfo, createSetCourseInfo, createSetCourseSteps } from '../store/create/actions';
import { goToTop } from '../services/vk';
import '../../styles/marathon-items.scss';

const MarathonItemList = () => {
    const dispatch = useDispatch();
    const tabIndex = useSelector(state => state.marathons.tabIndex);
    const courses = useSelector(state => state.marathons.courses[tabIndex]);
    const isAddedLeft = useSelector(state => state.storage.isAddedLeft);

    const openCourse = course => {
        dispatch(createResetCourseInfo());
        dispatch(createSetCourseInfo(course));
        dispatch(createSetCourseSteps([]));
        dispatch(push('/marathon-about'));
        goToTop();
    };

    const openMarathonCreate = () => {
        dispatch(push('/marathon-create'));
        goToTop();
    };

    const renderEmpty = () => {
        let text = '';
        switch (tabIndex) {
            case 1:
                text = 'У Вас пока нет марафонов';
                break;
            case 2:
                text = 'У Вас пока нет неопубликованных марафонов';
                break;
            case 3:
                text = 'В архиве пока нет марафонов';
                break;
        }

        return (
            <div className={`zero${+isAddedLeft ? ' zero--fixed-height' : ''}`}>
                <div className="zero--title">{text}</div>
                <Button onClick={openMarathonCreate}>Создать марафон</Button>
            </div>
        );
    };

    const renderList = () => {
        return courses.items.map(c => (
            <MarathonItem
                onClick={() => openCourse(c)}
                title={c.title}
                desc={c.description}
                image={c.avatar_photo !== undefined && c.avatar_photo[0].b !== undefined ? c.avatar_photo[0].b.url : ''}
                exercise={c.step_count !== undefined ? c.step_count : 0}
                rating={c.rating}
                key={`mil-${tabIndex}-${c.course_id}`}
            />
        ));
    };

    return (
        <div className="marathon-item--list">
            <div className={'marathon-item--list-inner'}>
                {courses.isLoaded && courses.total !== 0 && renderList()}
                {courses.isLoaded && courses.total === 0 && renderEmpty()}
            </div>
        </div>
    );
};

export default MarathonItemList;
