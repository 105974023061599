export const CREATE_SET_MARATHON_TITLE = 'CREATE_SET_MARATHON_TITLE';
export const CREATE_SET_MARATHON_DESC = 'CREATE_SET_MARATHON_DESC';
export const CREATE_SET_COVER = 'CREATE_SET_COVER';
export const CREATE_SET_AVATAR = 'CREATE_SET_AVATAR';
export const CREATE_SET_COVER_ID = 'CREATE_SET_COVER_ID';
export const CREATE_SET_AVATAR_ID = 'CREATE_SET_AVATAR_ID';
export const CREATE_SET_CATEGORIES = 'CREATE_SET_CATEGORIES';
export const CREATE_SELECT_CATEGORY = 'CREATE_SELECT_CATEGORY';
export const CREATE_SET_VIDEO_LINK = 'CREATE_SET_VIDEO_LINK';
export const CREATE_SET_VIDEO_OWNER_AND_ID = 'CREATE_SET_VIDEO_OWNER_AND_ID';
export const CREATE_SET_VIDEO_PREVIEW_URL = 'CREATE_SET_VIDEO_PREVIEW_URL';
export const CREATE_SET_SELECTED_COURSE_ID = 'CREATE_SET_SELECTED_COURSE_ID';
export const CREATE_RESET_COURSE_INFO = 'CREATE_RESET_COURSE_INFO';
export const CREATE_SET_MARATHONS_AMOUNT = 'CREATE_SET_MARATHONS_AMOUNT';
export const CREATE_SET_COURSE_STEPS = 'CREATE_SET_COURSE_STEPS';
export const CREATE_ADD_COURSE_STEPS = 'CREATE_ADD_COURSE_STEPS';
export const CREATE_SET_COURSE_INFO = 'CREATE_SET_COURSE_INFO';
export const CREATE_SET_MARATHON_STATUS = 'CREATE_SET_MARATHON_STATUS';
export const CREATE_SET_IS_MARATHON_JUST_CREATED = 'CREATE_SET_IS_MARATHON_JUST_CREATED';
export const CREATE_DELETE_STEP = 'CREATE_DELETE_STEP';
export const CREATE_REPLACE_STEP = 'CREATE_REPLACE_STEP';
export const CREATE_SET_IS_OPEN_SETTINGS = 'CREATE_SET_IS_OPEN_SETTINGS';
export const CREATE_COPY_SETTINGS = 'CREATE_COPY_SETTINGS';
export const CREATE_RESTORE_SETTINGS = 'CREATE_RESTORE_SETTINGS';
export const CREATE_SET_IS_AVATAR_EDITED = 'CREATE_SET_IS_AVATAR_EDITED';
export const CREATE_SET_IS_COVER_EDITED = 'CREATE_SET_IS_COVER_EDITED';
export const CREATE_SET_IS_VIDEO_EDITED = 'CREATE_SET_IS_VIDEO_EDITED';
export const CREATE_SET_IS_COPIED = 'CREATE_SET_IS_COPIED';
